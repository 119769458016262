import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default class Notification extends Component {


constructor(props) {
    super(props);
    this.state = {
        visible: false,
        isLoading: false,
        isError: false,
        title: "",
        description: '',
    };
}

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }


  render() {

    return (
        <div style={this.state.visible ? {zIndex: "999"} : { display: 'none' }}>
            <div className="modal pointer-events-none fixed flex items-center justify-center right-0 bottom-0 mb-2">            
                <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto">
            
                    <div className="flex max-w-md bg-white shadow-lg overflow-hidden">
                        <div className="w-2 bg-main-200"></div>
                        <div className="flex items-center px-2 py-3">
                            <div className="mx-3">
                                <h2 className="text-xl font-semibold text-gray-800"> { this.state.isLoading && <FontAwesomeIcon icon={faSpinner} className="fa-spin mr-1"/> } {this.state.title}</h2>
                                <p className="text-gray-600">{this.state.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }

  hide() {
    this.setState({ 
        visible: false,
        isLoading: false,
        isError: false,
        title: "",
        description: "",
      });
  }

show(title, description, isLoading, isError, duration) {
    this.setState({
        visible: true,
        isLoading: isLoading,
        isError: isError,
        title: title,
        description: description,
      });


    if (duration > 0) {
        setTimeout(function(){ 
            this.setState({ 
                visible: false,
                isLoading: false,
                isError: false,
                title: "",
                description: "",
              });
         }.bind(this), duration);
    }
}

}