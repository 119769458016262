import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Notification from '../components/Notification';
import TicketDialog from '../components/TicketDialog';

import { 
  dbCreateTicket,
  dbUpdateTicket,
  dbGetTickets,
  dbDeleteTicket} from '../helpers/db';

export default class Tickets extends Component {

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;

    this.state = {
      tickets: [],
      activeTicket: "",
      currentBuildingId: id
    };

    this.newTicket = this.newTicket.bind(this);
    this.openTicket = this.openTicket.bind(this);
  }

  componentDidMount() {
    dbGetTickets(this.state.currentBuildingId, function(data) {
      this.setState({ 
        tickets: data
      });
    }.bind(this));
  }

  openTicket(ticket) {
    var NotificationObject = this._notification;
    var self = this;

    self._ticketDialog.open(ticket, function(newTicket) {
      var id = newTicket.id;
      delete newTicket.id;

      dbUpdateTicket(self.state.currentBuildingId, id, newTicket, NotificationObject, function() {
        dbGetTickets(self.state.currentBuildingId, function(data) {
          self.setState({ 
            tickets: data
          });
        });
      });
    }, function(id) {
      dbDeleteTicket(self.state.currentBuildingId, id, NotificationObject, function() {
        dbGetTickets(self.state.currentBuildingId, function(data) {
          self.setState({ 
            tickets: data
          });
        });
      });
    });
  }

  newTicket() {
    var NotificationObject = this._notification;
    var self = this;

    self._ticketDialog.open(undefined, function(newTicket) {
      dbCreateTicket(self.state.currentBuildingId, newTicket, NotificationObject, function() {
        dbGetTickets(self.state.currentBuildingId, function(data) {
          self.setState({ 
            tickets: data
          });
        });
      });
    });
  }

  renderTicketFunction(ticket) {
    var prio = "Normal";
    var prioColor = "#496A80";
    if (ticket.priority === "low") {
      prio = "Niedrig";
      prioColor = "#87A5B9"
    } else if (ticket.priority === "high") {
      prio = "Hoch";
      prioColor = "#F00000";
    }

    var numberOfDays = Math.floor((new Date() -(1000 * ticket.createDate.seconds)) / (1000 * 3600 * 24));
    return (                
        <div className="bg-white rounded p-4 border-main-200 shadow cursor-pointer mb-4" key={ticket.id} onClick={() => this.openTicket(ticket)}>
          <div className="font-bold">{ticket.title}</div>
          <div className="text-xs text-gray-500">Vor {numberOfDays} Tagen erstellt</div>
          <div className="">{ticket.responsible}</div>
          <div className="mt-6">
            <span className="text-white py-1 px-4 rounded-full" style={{backgroundColor: prioColor}}>{prio}</span>
            <span className="text-white py-1 px-4 rounded-full ml-3" style={{backgroundColor: ticket.color}}>{ticket.category}</span>
          </div>
        </div>
      )
  }

  
  renderDoneTicketFunction(ticket) {
    var numberOfDays = Math.floor((new Date() -(1000 * ticket.createDate.seconds)) / (1000 * 3600 * 24));
    return (                
        <div className="bg-white rounded p-4 border-main-200 shadow cursor-pointer mb-4" key={ticket.id} onClick={() => this.openTicket(ticket)}>
          <div className="font-bold">{ticket.title}</div>
          <div className="text-xs text-gray-500">Vor {numberOfDays} Tagen erstellt</div>
        </div>
      )
  }

  render() {

    var openTickets = this.state.tickets.filter(ticket => (ticket.state === "open"));
    var progressTickets = this.state.tickets.filter(ticket => (ticket.state === "progress"));
    var doneTickets = this.state.tickets.filter(ticket => (ticket.state === "done"));

    var openTicketsList = openTickets.map(this.renderTicketFunction.bind(this));
    var progressTicketsList = progressTickets.map(this.renderTicketFunction.bind(this));
    var doneTicketsList = doneTickets.map(this.renderDoneTicketFunction.bind(this));

    return (
      <div className="flex md:flex-row-reverse flex-wrap">
        <Notification onRef={ref => (this._notification = ref)}></Notification>
        <TicketDialog onRef={ref => (this._ticketDialog = ref)}></TicketDialog>
		
         <div className="w-full h-screen md:w-5/6 bg-white">
           <Navbar title="Tickets"></Navbar>
           <div className="p-2 h-full pb-32">
           <button onClick={this.newTicket} className="inline-block text-sm px-4 ml-4 py-2 leading-none border rounded text-main-100 border-main-100 hover:border-transparent hover:text-white hover:bg-main-200 mt-2">Neues Ticket</button>

            <div className="flex">
              <div className="w-1/3 border border-grey-100 rounded m-4 bg-gray-100">
                <div className="rounded-t bg-main-100 text-white p-4 justify-between flex mb-4"><span>Offen</span><span className="text-xs">0 Tickets</span></div>
                <div className="p-4">
                  {openTicketsList}
                </div>
              </div>

              <div className="w-1/3 border border-grey-100 rounded m-4 bg-gray-100">
                <div className="rounded-t bg-main-100 text-white p-4 justify-between flex mb-4"><span>In Bearbeitung</span><span className="text-xs">0 Tickets</span></div>
                <div className="p-4">
                  {progressTicketsList}
                </div>
              </div>

              <div className="w-1/3 border border-grey-100 rounded m-4 bg-gray-100">
                <div className="rounded-t bg-main-100 text-white p-4 justify-between flex mb-4"><span>Abgeschlossen</span><span className="text-xs">0 Tickets</span></div>
                <div className="p-4">
                  {doneTicketsList}
                </div>
              </div>
            </div>

           </div>
         </div>
		 
        <Sidebar active="tickets" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}