import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Notification from '../components/Notification';
import TaskDialog from '../components/TaskDialog';
import TaskViewDialog from '../components/TaskViewDialog';

import { 
  dbAddEntry,
  dbUpdateEntry,
  dbDeleteEntry } from '../helpers/db_entry';

  import { 
    dbGetCurrentTasks,
    dbGetFutureTasks } from '../helpers/db_maintenance';
  

export default class Maintenance extends Component {

  
  constructor(props) {
      super(props);
      const { id } = this.props.match.params;

      this.state = {
          page: "maintenance",
          currentBuildingId: id,
          currenTasks: [],
          futureOnceTasks: [],
          futureRecurringTasks: []
      };

      this.openTaskDialog = this.openTaskDialog.bind(this);
      this.openEditTaskDialog = this.openEditTaskDialog.bind(this);
      this.openViewTaskDialog = this.openViewTaskDialog.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    dbGetCurrentTasks(this.state.currentBuildingId, function(data) {
      this.setState({ 
        currenTasks: data
      });
    }.bind(this));

    dbGetFutureTasks(this.state.currentBuildingId, function(data) {
      var futureOnceTasks = [];
      var futureRecurringTasks = [];

      if (data && data.length > 0) {
        for (var i=0; i<data.length; i++) {
          if (data[i].recurring) {
            futureRecurringTasks.push(data[i]);
          } else {
            futureOnceTasks.push(data[i]);
          }
        }
      }

      this.setState({ 
        futureOnceTasks: futureOnceTasks,
        futureRecurringTasks: futureRecurringTasks
      });
    }.bind(this));
  }

  openTaskDialog() {
    var NotificationObject = this._notification;
    var self = this;

    this._taskDialog.open(true, undefined, function(task) {  
      dbAddEntry(self.state.currentBuildingId, self.state.page, task, NotificationObject, function() {
        self.refresh();
      });
    }, undefined);
  }

  openEditTaskDialog(event, task) {
    event.stopPropagation();
    var NotificationObject = this._notification;
    var self = this;

    this._taskDialog.open(true, task, function(newTask) {  
      dbUpdateEntry(self.state.currentBuildingId, self.state.page, task.id, newTask, NotificationObject, function() {
        self.refresh();
      });
    }, undefined);
  }

  openViewTaskDialog(task) {
    var NotificationObject = this._notification;
    var self = this;

    this._taskViewDialog.open(task, function(id) {
      if (task.recurring) {
        var nextDate = new Date(task.dueDate);
        nextDate.setDate(nextDate.getDate() + (task.intervall * 7));
        var dd = String(nextDate.getDate()).padStart(2, '0');
        var mm = String(nextDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = nextDate.getFullYear();
    
        var dateString = yyyy + "-" + mm + "-" + dd;

        task.dueDate = dateString;
        dbUpdateEntry(self.state.currentBuildingId, self.state.page, task.id, task, NotificationObject, function() {
          self.refresh();
        });
      } else {
        task.statusOpen = false;
        dbUpdateEntry(self.state.currentBuildingId, self.state.page, task.id, task, NotificationObject, function() {
          self.refresh();
        });
      }
    });
  }

  renderTask(task) {
      var prioText = "Niedrig";
      var prioColor = "hidden";
      if (task.priority === "MEDIUM") {
        prioText = "Mittel";
        prioColor = "bg-orange-500 ml-4 text-white text-sm rounded p-1";
      } else if (task.priority === "HIGH") {
        prioText = "Hoch";
        prioColor = "bg-red-500 ml-4 text-white text-sm rounded p-1";
      }

      var recurringClasses = "hidden";
      if (task.recurring) {
        recurringClasses = "bg-gray-500 ml-4 text-white text-sm rounded p-1";
      }

      return (
        <div key={task.id} className="flex mb-1 border-grey-100 bg-white rounded px-6 py-4 cursor-pointer" onClick={() => this.openViewTaskDialog(task)}>
          <div className="w-1/2">
            <div className="text-black font-bold">{task.title}<span className="text-gray-500 font-normal text-xs ml-2" onClick={(event) => this.openEditTaskDialog(event, task)}>(Ändern)</span></div>
            <div>
              <span className="text-gray-500">Fällig {task.dueDate}</span>
              <span className={prioColor}>{prioText}</span>
            </div>
          </div>
          <div className="w-1/2 text-right">
            <div className="text-gray-500">{task.responsible}</div>
            <span className={recurringClasses}>Wiederkehrend</span>
          </div>
        </div>                
    )
  }


  render() {

    var currentTasksList = this.state.currenTasks.map(this.renderTask.bind(this));
    var futureOnceTaskList = this.state.futureOnceTasks.map(this.renderTask.bind(this));
    var futureRecurringTaskList = this.state.futureRecurringTasks.map(this.renderTask.bind(this));

    return (
      <div className="flex md:flex-row-reverse flex-wrap">

        <Notification onRef={ref => (this._notification = ref)}></Notification>
        <TaskDialog onRef={ref => (this._taskDialog = ref)}></TaskDialog>
        <TaskViewDialog onRef={ref => (this._taskViewDialog = ref)}></TaskViewDialog>

         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Wartung"></Navbar>

           <div className="">
            <button onClick={this.openTaskDialog} className="inline-block text-sm px-4 ml-4 mb-6 mt-4 py-2 leading-none border rounded text-main-100 border-main-100 hover:border-transparent hover:text-white hover:bg-main-200">Neue Aufgabe erstellen</button>

            <div className="border border-grey-100 rounded m-4 p-6">
              <h3 className="mb-8 font-bold">Aktuelle Aufgaben</h3>
              {currentTasksList}
            </div>

            <div className="border border-grey-100 rounded m-4 p-6">
              <h3 className="mb-8 font-bold">Zukünftige einmalige Aufgaben</h3>
              {futureOnceTaskList}
            </div>

            <div className="border border-grey-100 rounded m-4 p-6">
              <h3 className="mb-8 font-bold">Zukünftige wiederholende Aufgaben</h3>
              {futureRecurringTaskList}
            </div>
          </div>
         </div>
		
        <Sidebar active="maintenance" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}