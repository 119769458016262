import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Index from './pages/Index';
import Home from './pages/Home';
import Building from './pages/Building';
import Service from './pages/Service';
import Tickets from './pages/Tickets';
import Tenants from './pages/Tenants';
import Items from './pages/Items';
import Assets from './pages/Assets';
import Defects from './pages/Defects';
import Maintenance from './pages/Maintenance';
import Communication from './pages/Communication';
import Admin from './pages/Admin';
import Signup from './pages/Signup';
import Login from './pages/Login';
import News from './pages/News';
import NoMatch from './pages/NoMatch';

import { auth } from './services/firebase';


function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === false
        ? <Component {...props} />
        : <Redirect to='/home' />}
    />
  )
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  }

  componentDidMount() {
    auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });
  }

  render() {
    return this.state.loading === true ? <h2>Loading...</h2> : (
      <Router>
        <Switch>
          <Route exact path="/" component={Index}></Route>
          <PrivateRoute path="/home" authenticated={this.state.authenticated} component={Home}></PrivateRoute>
          <PrivateRoute path="/:id/building" authenticated={this.state.authenticated} component={Building}></PrivateRoute>
          <PrivateRoute path="/:id/news/:newsId?" authenticated={this.state.authenticated} component={News}></PrivateRoute>
          <PrivateRoute path="/admin" authenticated={this.state.authenticated} component={Admin}></PrivateRoute>
          <PrivateRoute path="/:id/communication" authenticated={this.state.authenticated} component={Communication}></PrivateRoute>
          <PrivateRoute path="/:id/assets/:assetId?" authenticated={this.state.authenticated} component={Assets}></PrivateRoute>
          <PrivateRoute path="/:id/defects/:defectId?" authenticated={this.state.authenticated} component={Defects}></PrivateRoute>
          <PrivateRoute path="/:id/maintenance" authenticated={this.state.authenticated} component={Maintenance}></PrivateRoute>
          <PrivateRoute path="/:id/tenants/:tenantId?" authenticated={this.state.authenticated} component={Tenants}></PrivateRoute>
          <PrivateRoute path="/:id/items/:itemId?" authenticated={this.state.authenticated} component={Items}></PrivateRoute>
          <PrivateRoute path="/:id/service/:serviceId?" authenticated={this.state.authenticated} component={Service}></PrivateRoute>
          <PrivateRoute path="/:id/tickets" authenticated={this.state.authenticated} component={Tickets}></PrivateRoute>
          <PublicRoute path="/signup" authenticated={this.state.authenticated} component={Signup}></PublicRoute>
          <PublicRoute path="/login" authenticated={this.state.authenticated} component={Login}></PublicRoute>
          <PublicRoute authenticated={this.state.authenticated} component={NoMatch}></PublicRoute>
        </Switch>
      </Router>
    );
  }
}

export default App;