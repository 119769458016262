import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import EntryList from '../components/EntryList';
import Notification from '../components/Notification';
import DocumentList from '../components/DocumentList';


import { 
  dbAddEntry,
  dbGetEntries,
  dbUpdateEntry,
  dbDeleteEntry } from '../helpers/db_entry';

  const emptyNews = {
    notes: "",
    title: ""
  };

export default class News extends Component {

  
  constructor(props) {
      super(props);
      const { id, newsId } = this.props.match.params;

      this.state = {
          page: "news",
          news: [],
          activeNews: emptyNews,
          notesEditable: false,
          currentBuildingId: id,
          urlNewsId: newsId
      };

      this.handleChange = this.handleChange.bind(this);
      this.onActiveNewsChanged = this.onActiveNewsChanged.bind(this);
      this.onNewsAdded = this.onNewsAdded.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleNotesEdit = this.handleNotesEdit.bind(this);
      this.deleteNews = this.deleteNews.bind(this);
  }

  componentDidMount() {
    dbGetEntries(this.state.currentBuildingId, this.state.page, function(data) {
      if (this.state.urlNewsId) {
        var activeNews = emptyNews;

        for (var i=0; i<data.length; i++) {
          if (data[i].id === this.state.urlNewsId) {
            activeNews = data[i];
            break;
          }
        }

        this.setState({ 
          news: data,
          activeNews: activeNews
        });
      } else {
        this.setState({ 
          news: data
        });
      }
    }.bind(this));
  }

  handleChange(event) {
    var newActiveNews = this.state.activeNews;
    newActiveNews[event.target.name] = event.target.value;

    this.setState({
      activeNews: newActiveNews
      });
  }

  handleSave() {
    this.setState({ notesEditable: false });
    
    var updatedNews = {
      notes: this.state.activeNews.notes || "",
      title: this.state.activeNews.title || ""
    };

      console.log("handle save:")
      console.log(this.state.activeNews)

    dbUpdateEntry(this.state.currentBuildingId, this.state.page, this.state.activeNews.id, updatedNews, this._notification);
  }

  handleNotesEdit() {
    this.setState({ notesEditable: true });
  }


  onActiveNewsChanged(tenant) {
    this.props.history.push({
      pathname: "/" + this.state.currentBuildingId + "/news/" + tenant.id
    })

    if (!tenant.contacts) {
      tenant.contacts = [];
    }
    this.setState({ 
      activeNews: tenant,
      noteEditable: false,
      infoEditable: false
    });
  }

  deleteNews() {
    if (window.confirm("Soll dieserr Newseintrag wirklich gelöscht werden?")) {
      var self = this;
      var id = this.state.activeNews.id;
      dbDeleteEntry(this.state.currentBuildingId, this.state.page, id, this._notification, function() {
        var newNews = self.state.news.filter(t => (t.id !== id));
        emptyNews.title = "";
        self.setState({ news: newNews, activeNews: emptyNews });
      });
    }
  }

  onNewsAdded(title) {
    var NotificationObject = this._notification;
    var self = this;
    var newNews = emptyNews;
    newNews.title = title;

    dbAddEntry(this.state.currentBuildingId, this.state.page, newNews, NotificationObject, function() {
      dbGetEntries(self.state.currentBuildingId, self.state.page, function(data) {
        self.setState({ 
          news: data
        });
      });
    });
  }



  render() {

    var detailVisibility = (this.state.activeNews.title  && this.state.activeNews.title.length > 0) ? "" : " hidden";
    var newsId = (this.state.activeNews.id  && this.state.activeNews.id.length > 0) ? this.state.activeNews.id : undefined;

    var noteInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.notesEditable) {
      noteInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    return (
      <div className="flex md:flex-row-reverse flex-wrap">
        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="News"></Navbar>

           <div className="flex">
            <div className="w-3/12 h-screen">
              <EntryList entries={this.state.news} selected={this.state.activeNews} title="News hinzufügen" onActiveChanged={(a) => this.onActiveNewsChanged(a)} onEntryAdded={(a) => this.onNewsAdded(a)}></EntryList>
            </div>
            <div className="w-9/12">
            <div className={detailVisibility}>

            <div className="flex">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Notizen
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.notesEditable} onClick={this.handleNotesEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.notesEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                  <textarea rows="30" readOnly={this.state.notesEditable ? false : "readonly"} value={this.state.activeNews.notes} onChange={this.handleChange} name="notes" className={noteInputClasses} ></textarea>
                </div>
              </div>
            </div>


            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList currentBuildingId={this.state.currentBuildingId} folderId={this.state.activeNews.gDriveFolderId}></DocumentList>
              </div>
            </div>

            <div className="flex mb-10 float-right">
              <div className="">
                  <button onClick={this.deleteNews} className="mr-4 float-right text-sm mt-4 px-4 ml-4 py-2 leading-none border rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700">News löschen</button>
                </div>
            </div>

            </div>


            </div>
          </div>

         </div>
		
        <Sidebar active="news" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}