import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import EntryList from '../components/EntryList';
import Notification from '../components/Notification';
import DocumentList from '../components/DocumentList';
import AdditionalContacts from '../components/AdditionalContacts';
import History from '../components/History';

import { IconPicker, IconPickerItem } from 'react-fa-icon-picker';

import { 
  dbAddEntry,
  dbGetEntries,
  dbUpdateEntry,
  dbDeleteEntry } from '../helpers/db_entry';


const emptyItem =  {
  title: "",
  icon: "",
  infoModel: "",
  infoSeller: "",
  infoLocation: "",
  infoPrice: "",
  infoBuyDate: "",
  infoGuaranteeDate: "",
  notes: "",
  contacts: [],
  events: []
};

export default class Assets extends Component {

  
  constructor(props) {
      super(props);
      const { id, assetId } = this.props.match.params;

      this.state = {
          page: "assets",
          activeItem: emptyItem,
          items: [],
          noteEditable: false,
          infoEditable: false,
          currentBuildingId: id,
          urlAssetId: assetId
      };

      this.handleChange = this.handleChange.bind(this);
      this.onactiveItemChanged = this.onactiveItemChanged.bind(this);
      this.onItemAdded = this.onItemAdded.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleNoteEdit = this.handleNoteEdit.bind(this);
      this.handleInfoEdit = this.handleInfoEdit.bind(this);
      this.deleteItem = this.deleteItem.bind(this);
      this.addContact = this.addContact.bind(this);
      this.deleteContact = this.deleteContact.bind(this);
      this.addEvent = this.addEvent.bind(this);
      this.deleteEvent = this.deleteEvent.bind(this);
      this.editContact = this.editContact.bind(this);
      this.editEvent = this.editEvent.bind(this);
      this.iconChanged = this.iconChanged.bind(this);
  }

  componentDidMount() {
    //Load all assets
    dbGetEntries(this.state.currentBuildingId, this.state.page, function(data) {
      if (this.state.urlAssetId) {
        var activeItem = emptyItem;

        for (var i=0; i<data.length; i++) {
          if (data[i].id === this.state.urlAssetId) {
            activeItem = data[i];
            break;
          }
        }

        this.setState({ 
          items: data,
          activeItem: activeItem
        });
      } else {
        this.setState({ 
          items: data
        });
      }
    }.bind(this));
  }

  handleChange(event) {
    var newActiveItem = this.state.activeItem;
    newActiveItem[event.target.name] = event.target.value;
    this.setState({
      activeItem: newActiveItem
    });

  }

  handleSave() {
    this.setState({ noteEditable: false, infoEditable: false });
    var updatedItem = {
      title: this.state.activeItem.title || "",
      icon: this.state.activeItem.icon || "",
      infoModel: this.state.activeItem.infoModel || "",
      infoSeller: this.state.activeItem.infoSeller || "",
      infoLocation: this.state.activeItem.infoLocation || "",
      infoPrice: this.state.activeItem.infoPrice || "",
      infoBuyDate: this.state.activeItem.infoBuyDate || "",
      infoGuaranteeDate: this.state.activeItem.infoGuaranteeDate || "",
      notes: this.state.activeItem.notes || "",
      contacts: this.state.activeItem.contacts || [],
      events: this.state.activeItem.events || []
    };


    dbUpdateEntry(this.state.currentBuildingId, this.state.page, this.state.activeItem.id, updatedItem, this._notification);
  }

  handleNoteEdit() {
    this.setState({ noteEditable: true });
  }

  handleInfoEdit() {
    this.setState({ infoEditable: true });
  }

  iconChanged(icon) {
    var newItem =  this.state.activeItem;
    newItem.icon = icon;

    this.setState({ 
      activeItem: newItem
    });
  }

  onactiveItemChanged(item) {
    this.props.history.push({
      pathname: "/" + this.state.currentBuildingId + "/assets/" + item.id
    })
    this.setState({ 
      activeItem: item,
      noteEditable: false,
      infoEditable: false
    });
  }

  deleteItem() {
    if (window.confirm("Soll der Eintrag wirklich gelöscht werden?")) {
      var self = this;
      var id = this.state.activeItem.id;
      dbDeleteEntry(this.state.currentBuildingId, this.state.page, id, this._notification, function() {
        var newItems = self.state.items.filter(s => (s.id !== id));
        self.setState({ items: newItems, activeItem: emptyItem });
      });
    }
  }

  onItemAdded(item) {
    var NotificationObject = this._notification;
    var self = this;
    var newItem = emptyItem;
    newItem.title = item;

    dbAddEntry(this.state.currentBuildingId, this.state.page, newItem, NotificationObject, function() {
      dbGetEntries(self.state.currentBuildingId, self.state.page, function(data) {
        self.setState({ 
          items: data
        });
      });
    });
  }

  addContact(newContact) {
    if (!this.state.activeItem.contacts) {
      this.state.activeItem.contacts = [];
    }
    this.state.activeItem.contacts.push(newContact);
    this.handleSave();
  }

  deleteContact(index, name) {
    this.state.activeItem.contacts.splice(index, 1);
    this.handleSave();
  }

  editContact(newContacts) {
    var editedActiveItem = this.state.activeItem;
    editedActiveItem.contacts = newContacts;
    this.setState({ 
      activeItem: editedActiveItem
    });
    this.handleSave();
  }

  addEvent(newEvent) {
    if (!this.state.activeItem.events) {
      this.state.activeItem.events = [];
    }
    this.state.activeItem.events.push(newEvent);
    this.handleSave();
  }

  editEvent(newEvents) {
    var editedActiveItem = this.state.activeItem;
    editedActiveItem.events = newEvents;
    this.setState({ 
      events: editedActiveItem
    });
    this.handleSave();
  }

  deleteEvent(index) {
    this.state.activeItem.events.splice(index, 1);
    this.handleSave();
  }


  render() {
    var detailVisibility = (this.state.activeItem.title  && this.state.activeItem.title.length > 0) ? "" : " hidden";
    var itemId = (this.state.activeItem.id  && this.state.activeItem.id.length > 0) ? this.state.activeItem.id : undefined;


    var noteInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.noteEditable) {
      noteInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    var infoInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    var iconChooserClasses = "hidden";
    var iconDisplayClasses = "md:w-2/3";
    if (this.state.infoEditable) {
      iconChooserClasses = "md:w-2/3";
      iconDisplayClasses = "hidden";
      infoInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    return (
      <div className="flex md:flex-row-reverse flex-wrap">

        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Anlagen & Bauteile" description="Alle im Gebäude verbauten Anlagen und Bauteile"></Navbar>

           <div className="flex">
            <div className="w-3/12 h-screen">
              <EntryList entries={this.state.items} selected={this.state.activeItem} title="Eintrag hinzufügen" onActiveChanged={(a) => this.onactiveItemChanged(a)} onEntryAdded={(a) => this.onItemAdded(a)}></EntryList>
            </div>
            <div className="w-9/12">
            <div className={detailVisibility}>

            <div className="flex">
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Informationen 
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.infoEditable} onClick={this.handleInfoEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.infoEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>


                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Icon:</label>
                  </div>
                  <div className={iconChooserClasses}>
                    <IconPicker value={this.state.activeItem.icon} onChange={this.iconChanged}></IconPicker>
                  </div>
                  <div className={iconDisplayClasses}>
                    <IconPickerItem containerStyles={{}} icon={this.state.activeItem.icon} size={18} color="#000"/> 
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Beschreibung:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.title} onChange={this.handleChange} name="title" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Marke/Modell:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.infoModel} onChange={this.handleChange} name="infoModel" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>
           
                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Firma:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.infoSeller} onChange={this.handleChange} name="infoSeller" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Ort im Gebäude:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.infoLocation} onChange={this.handleChange} name="infoLocation" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Garantie bis:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.infoGuaranteeDate} onChange={this.handleChange} name="infoGuaranteeDate" className={infoInputClasses} type="date" placeholder=""></input>
                  </div>
                </div>              

              </div>
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Notizen
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.noteEditable} onClick={this.handleNoteEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.noteEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                <textarea rows="15" readOnly={this.state.noteEditable ? false : "readonly"} value={this.state.activeItem.notes} onChange={this.handleChange} name="notes" className={noteInputClasses} ></textarea>

                </div>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <AdditionalContacts contacts={this.state.activeItem.contacts} addFunction={this.addContact} deleteFunction={this.deleteContact} editFunction={this.editContact}></AdditionalContacts>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <History currentBuildingId={this.state.currentBuildingId} events={this.state.activeItem.events} addFunction={this.addEvent} deleteFunction={this.deleteEvent} editFunction={this.editEvent}></History>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList currentBuildingId={this.state.currentBuildingId} folderId={this.state.activeItem.gDriveFolderId}></DocumentList>
              </div>
            </div>

            <div className="flex mb-10 float-right">
              <div className="">
                    <button onClick={this.deleteItem} className="mr-4 text-sm mt-4 px-4 ml-4 py-2 leading-none border rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700">Eintrag löschen</button>
                </div>
            </div>

          </div>


            </div>
          </div>

         </div>
		
        <Sidebar active="assets" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}