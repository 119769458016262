import React, { Component } from 'react';

export default class EventDialog extends Component {


constructor(props) {
    super(props);
    this.state = {
        open: false,
        callback: undefined,
        title: "",
        date: "",
        description: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
}

close() {
    this.setState({ 
        open: false,
        title: "",
        date: "",
        description: ""
      });
}

save() {
    this.state.callback(this.state.title, this.state.date, this.state.description)
    this.close();
}

open(editingEvent, callback) {
  if (editingEvent) {
    this.setState({ 
      open: true,
      callback: callback,
      title: editingEvent.title,
      date: editingEvent.date,
      description: editingEvent.description
    });
  } else {
    this.setState({ 
      open: true,
      callback: callback
    });
  }
}

handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

componentDidMount() {
    this.props.onRef(this)
}
componentWillUnmount() {
    this.props.onRef(undefined)
}


  render() {
    var modalClasses = "z-10 modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center";
    if (this.state.open) {
        modalClasses = "z-10 modal fixed w-full h-full top-0 left-0 flex items-center justify-center";
    }

    return (
        <div className={modalClasses}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

          <div className="modal-content py-4 text-left px-6">
            <div className="flex justify-between items-center pb-3">
              <p className="text-lg font-bold">Weiteres Ereignis hinzufügen</p>
            </div>
    
            <div className="mb-4">
                <label className="block mb-2">Titel</label>
                <input onChange={this.handleChange} name="title" value={this.state.title} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Reparatur"></input>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Datum</label>
                <input onChange={this.handleChange} name="date" value={this.state.date} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="date" placeholder=""></input>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Beschreibung</label>
                <input onChange={this.handleChange} name="description" value={this.state.description} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="optional"></input>
            </div>

    
            <div className="flex justify-end pt-2">
              <button className="px-4 bg-transparent p-3 rounded text-main-100 hover:bg-main-200 hover:text-white mr-2" onClick={this.close}>Abbrechen</button>
              <button className="modal-close px-4 bg-main-100 p-3 rounded text-white hover:bg-main-200" onClick={this.save}>Speichern</button>
            </div>
            
          </div>
        </div>
      </div>
    )
  }

}