import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import DocumentList from '../components/DocumentList';
import Notification from '../components/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


import { 
  updateBuildingInfos, 
  getBuildingInfos } from '../helpers/db';

export default class Building extends Component {

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;

    this.state = {
      page: "building",
      infoEditable: false,
      notesEditable: false,
      title: '',
      street: "",
      city: '',
      rentalArea: '',
      year: '',
      images: [],
      currentBuildingId: id
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleInfoEdit = this.handleInfoEdit.bind(this);
    this.handleNotesEdit = this.handleNotesEdit.bind(this);
  }

  componentDidMount() {    
    getBuildingInfos(this.state.currentBuildingId, function(data) {
      this.setState({ 
        title: data.title,
        street: data.street,
        city: data.city,
        rentalArea: data.rentalArea,
        year: data.year,
        notes: data.notes
      });
    }.bind(this));
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSave(event) {
    this.setState({ infoEditable: false, notesEditable: false });

    updateBuildingInfos(this.state.currentBuildingId, this.state, this._notification);
  }

  handleInfoEdit(event) {
    this.setState({ infoEditable: true });
  }

  handleNotesEdit() {
    this.setState({ notesEditable: true });
  }

  render() {
        var infoInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
        if (this.state.infoEditable) {
          infoInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
        };
        var noteInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
        if (this.state.notesEditable) {
          noteInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
        }

        this.state.images.sort(function(a, b){return a.description >= b.description});
        var imagesList = this.state.images.map(function(image) {    
            var key = image.fileName + "-" + image.description;
      
            return (                
              <div className="flex flex-wrap -mx-3 mb-2" key={key}>      
                <div className="w-full md:w-10/12 px-3 mb-6 md:mb-0">
                  <a href={image.url} target="_blank" rel="noopener noreferrer">{image.description}</a>
                </div>

                <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0 items-end">
                  <button onClick={() => this.deleteImage(image.id, image.fileName)} className="align-bottom mr-4 ml-4"><FontAwesomeIcon icon={faTimes} /></button>
                </div>
              </div>
              )}.bind(this));

    return (
      <div className="flex md:flex-row-reverse flex-wrap">
        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Übersicht"></Navbar>
           <div className="p-2">
            <div className="flex">
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Allgemeine Informationen 
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.infoEditable} onClick={this.handleInfoEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.infoEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                
                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Bezeichnung:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.title} onChange={this.handleChange} name="title" className={infoInputClasses} type="text" placeholder="Skyline Office"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Straße:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.street || ""} onChange={this.handleChange} name="street" className={infoInputClasses} type="text" placeholder="Beispielstraße 26"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Ort:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.city || ""} onChange={this.handleChange}  className={infoInputClasses} name="city" type="text" placeholder="Würzburg"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Mietfläche:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.rentalArea || ""} onChange={this.handleChange} name="rentalArea" className={infoInputClasses} type="text" placeholder="4000qm"></input>
                  </div>
                </div>
                
                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4" htmlFor="building_year">Baujahr:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.year || ""} onChange={this.handleChange} name="year" className={infoInputClasses} type="text" placeholder="2020"></input>
                  </div>
                </div>

              </div>
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Notizen
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.notesEditable} onClick={this.handleNotesEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.notesEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                  <textarea rows="15" readOnly={this.state.notesEditable ? false : "readonly"} value={this.state.notes} onChange={this.handleChange} name="notes" className={noteInputClasses} ></textarea>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList title="Dokumente (Allgemein)" currentBuildingId={this.state.currentBuildingId} folderId="1By8-HL4hkTKbVJ4UhRYWE-Nrg4ZXrDpy"></DocumentList>
              </div>
            </div>

            <div className="flex">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList title="Dokumente (Finanzen)" currentBuildingId={this.state.currentBuildingId} folderId="1Z84jLIYlNdR0vtMmN7PBNsTiZ0EBNTcC"></DocumentList>
              </div>
            </div>
           </div>
         </div>
		 
        <Sidebar active="building" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}