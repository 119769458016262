import {db, storage} from '../services/firebase';
import {createGDriveFolder, listGDriveFiles} from "./gdrive";

export async function dbAddEntry(currentBuildingId, page, data, NotificationObject, callback) {
    console.log("db call: dbAddEntry: " + page);
    NotificationObject.show("Bitte warten", "Daten werden gespeichert...", true, false, 0);


    createGDriveFolder(currentBuildingId, page, data.title, function(result) {
        if (result.error || !result.gDriveFolderId) {
            console.log("result: ")
            console.log(result)
            NotificationObject.show("Fehler", "Daten konnten nicht gespeichert werden: " + result.error, false, true, 3000);
        } else {
            data.gDriveFolderId = result.gDriveFolderId;
  
            db.collection("buildings").doc(currentBuildingId).collection(page).add(data)
                .then(function() {
                    NotificationObject.show("Erfolg", "Eintrag gespeichert.", false, false, 3000);
                    callback();
                })
                .catch(function(error) {
                    NotificationObject.show("Fehler", "Eintrag konnte nicht gespeichert werden.", false, true, 3000);
                });
        }
    });
}

export function dbGetEntries(currentBuildingId, page, callback) {
    console.log("db call: dbGetEvents: " + page);
    var result = [];
    db.collection("buildings").doc(currentBuildingId).collection(page).get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var obj = doc.data();
            obj.id = doc.id;
            result.push(obj);
        });

        callback(result);
    });
}

export function dbUpdateEntry(currentBuildingId, page, entryId, data, NotificationObject, callback) {
    console.log("db call: dbUpdateEntry: " + entryId + " on page " + page + " on building " + currentBuildingId);
    NotificationObject.show("Bitte warten", "Daten werden gespeichert...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).collection(page).doc(entryId).set(data, {merge: true})
    .then(function() {
        NotificationObject.show("Erfolg", "Daten gespeichert.", false, false, 3000);
        if (callback) {
            callback();
        }
    })
    .catch(function(error) {
        console.log(error)
        NotificationObject.show("Fehler", "Daten konnten nicht gespeichert werden: " + error, false, true, 3000);
    });
}

export function dbDeleteEntry(currentBuildingId, page, entryId, NotificationObject, callback) {
    console.log("db call: dbDeleteEntry: " + page);
    deleteEntryData(currentBuildingId, page, entryId, NotificationObject, callback);
}

function deleteEntryData(currentBuildingId, page, entryId, NotificationObject, callback) {
    console.log("db call: deleteEntryData: " + page);
    db.collection("buildings").doc(currentBuildingId).collection(page).doc(entryId).delete().then(function() {
        NotificationObject.show("Erfolg", "Eintrag wurde gelöscht.", false, false, 3000);
        callback();
    }).catch(function(error) {
        NotificationObject.show("Fehler", "Eintrag konnte nicht gelöscht werden.", false, true, 3000);
    });
}



export function dbGetEntryDocuments(currentBuildingId, folderId, callback) {
    console.log("db call: dbGetEntryDocuments: " + currentBuildingId + " - " + folderId);

    if (!folderId) {
        callback("Google Drive Ordner ID fehlt.", undefined);
        return;
    }

    listGDriveFiles(currentBuildingId, folderId, function(error, result) {
        if (error || result.error) {
            var errorMessage = error;
            if (!error) {
                errorMessage = result.error;
            }
            console.log("Error: " + errorMessage);
            callback(errorMessage, undefined);
        } else {
            if (result && result.files) {
                var finalResult = [];
                for (var i=0; i<result.files.length; i++) {
                    result.files[i].link = "https://drive.google.com/file/d/" + result.files[i].id;
                    finalResult.push(result.files[i]);
                }

                callback(undefined, finalResult);
            } else {
                callback(undefined, []);
            }
        }
    });
}