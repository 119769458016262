import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import EntryList from '../components/EntryList';
import Notification from '../components/Notification';
import DocumentList from '../components/DocumentList';
import History from '../components/History';


import { 
  dbAddEntry,
  dbGetEntries,
  dbUpdateEntry,
  dbDeleteEntry } from '../helpers/db_entry';

  
const defectStates = ["Erstellt (nicht gemeldet)", "Gemeldet", "In Bearbeitung", "Abgelehnt", "Erledigt"];
const defectPriorities = ["Niedrig", "Mittel", "Hoch"];

const emptyItem =  {
  title: "",
  state: defectStates[0],
  location: "",
  priority:  defectPriorities[0],
  createdBy: "",
  responsible: "",
  notes: "",
  events: []
};

export default class Defects extends Component {

  
  constructor(props) {
      super(props);
      const { id, defectId } = this.props.match.params;

      this.state = {
          page: "defects",
          activeItem: emptyItem,
          previousState: undefined,
          items: [],
          noteEditable: false,
          infoEditable: false,
          currentBuildingId: id,
          urlDefectId: defectId
      };

      this.handleChange = this.handleChange.bind(this);
      this.onactiveItemChanged = this.onactiveItemChanged.bind(this);
      this.onItemAdded = this.onItemAdded.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleNoteEdit = this.handleNoteEdit.bind(this);
      this.handleInfoEdit = this.handleInfoEdit.bind(this);
      this.deleteItem = this.deleteItem.bind(this);
      this.addEvent = this.addEvent.bind(this);
      this.deleteEvent = this.deleteEvent.bind(this);
      this.editEvent = this.editEvent.bind(this);
  }

  componentDidMount() {
    //Load all assets
    dbGetEntries(this.state.currentBuildingId, this.state.page, function(data) {
      if (this.state.urlDefectId) {
        var activeItem = emptyItem;

        for (var i=0; i<data.length; i++) {
          if (data[i].id === this.state.urlDefectId) {
            activeItem = data[i];
            break;
          }
        }

        this.setState({ 
          items: data,
          activeItem: activeItem,
          previousState: activeItem.state
        });
      } else {
        this.setState({ 
          items: data
        });
      }
    }.bind(this));
  }

  handleChange(event) {
    var newActiveItem = this.state.activeItem;
    newActiveItem[event.target.name] = event.target.value;
    this.setState({
      activeItem: newActiveItem
    });

  }

  handleSave() {

    var events = this.state.activeItem.events;
    if (!events) {
      events = [];
    }


    var updatedItem = {
      title: this.state.activeItem.title || "",
      state: this.state.activeItem.state || "",
      location: this.state.activeItem.location || "",
      priority: this.state.activeItem.priority || "",
      createdBy: this.state.activeItem.createdBy || "",
      responsible: this.state.activeItem.responsible || "",
      notes: this.state.activeItem.notes || "",
      events: events || []
    };


    this.setState({ noteEditable: false, infoEditable: false , previousState: this.state.activeItem.state});

    dbUpdateEntry(this.state.currentBuildingId, this.state.page, this.state.activeItem.id, updatedItem, this._notification);
  }

  handleNoteEdit() {
    this.setState({ noteEditable: true });
  }

  handleInfoEdit() {
    this.setState({ infoEditable: true });
  }

  onactiveItemChanged(item) {
    this.props.history.push({
      pathname: "/" + this.state.currentBuildingId + "/defects/" + item.id
    })
    this.setState({ 
      activeItem: item,
      noteEditable: false,
      infoEditable: false
    });
  }

  deleteItem() {
    if (window.confirm("Soll dieser Mangel wirklich gelöscht werden?")) {
      var self = this;
      var id = this.state.activeItem.id;
      dbDeleteEntry(this.state.currentBuildingId, this.state.page, id, this._notification, function() {
        var newItems = self.state.items.filter(s => (s.id !== id));
        self.setState({ items: newItems, activeItem: emptyItem });
      });
    }
  }

  onItemAdded(item) {
    var NotificationObject = this._notification;
    var self = this;
    var newItem = emptyItem;
    newItem.title = item;

    dbAddEntry(this.state.currentBuildingId, this.state.page, newItem, NotificationObject, function() {
      dbGetEntries(self.state.currentBuildingId, self.state.page, function(data) {
        self.setState({ 
          items: data
        });
      });
    });
  }

  addEvent(newEvent) {
    if (!this.state.activeItem.events) {
      this.state.activeItem.events = [];
    }
    this.state.activeItem.events.push(newEvent);
    this.handleSave();
  }

  editEvent(newEvents) {
    var editedActiveItem = this.state.activeItem;
    editedActiveItem.events = newEvents;
    this.setState({ 
      events: editedActiveItem
    });
    this.handleSave();
  }

  deleteEvent(index) {
    this.state.activeItem.events.splice(index, 1);
    this.handleSave();
  }


  render() {
    var detailVisibility = (this.state.activeItem.title  && this.state.activeItem.title.length > 0) ? "" : " hidden";
    var itemId = (this.state.activeItem.id  && this.state.activeItem.id.length > 0) ? this.state.activeItem.id : undefined;


    var noteInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.noteEditable) {
      noteInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    var infoInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.infoEditable) {
      infoInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    var selectClasses = infoInputClasses + " float-left";

    var defectStatesList = defectStates.map(function(defectState) {    
      return (         
        <option value={defectState} key={defectState}>{defectState}</option>       
        )});  

    var prioList = defectPriorities.map(function(prio) {    
      return (         
        <option value={prio} key={prio}>{prio}</option>       
        )});  


    var groupedItems = [];
    for (var i=0; i<this.state.items.length; i++) {
      var newItem = this.state.items[i];
      if (this.state.items[i].state === defectStates[3] || this.state.items[i].state === defectStates[4]) {
        var newItem = this.state.items[i];
        newItem.recurring = false;
      } else {
        newItem.recurring = true;
      }
      groupedItems.push(this.state.items[i]);

    }

    return (
      <div className="flex md:flex-row-reverse flex-wrap">

        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Mängel" description="Alle während der Bauphase aufgetretenen Mängel"></Navbar>

           <div className="flex">
            <div className="w-3/12 h-screen">
              <EntryList entries={groupedItems} selected={this.state.activeItem} title="Mangel hinzufügen" onActiveChanged={(a) => this.onactiveItemChanged(a)} onEntryAdded={(a) => this.onItemAdded(a)}></EntryList>
            </div>
            <div className="w-9/12">
            <div className={detailVisibility}>

            <div className="flex">
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Informationen 
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.infoEditable} onClick={this.handleInfoEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.infoEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4"> Beschreibung:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.title} onChange={this.handleChange} name="title" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Status:</label>
                  </div>
                  <div className="md:w-2/3">
                    <div>
                      <select disabled={this.state.infoEditable ? false : true} onChange={this.handleChange} name="state" value={this.state.activeItem.state} className={selectClasses}>
                          <option value="-">Bitte auswählen</option>
                          {defectStatesList}
                      </select>
                      <div className="-mt-8 pointer-events-none float-right inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>
                </div>
           
                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Ort im Gebäude:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.location} onChange={this.handleChange} name="location" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Priorität:</label>
                  </div>
                  <div className="md:w-2/3">
                    <div>
                      <select disabled={this.state.infoEditable ? false : true} onChange={this.handleChange} name="priority" value={this.state.activeItem.priority} className={selectClasses}>
                          <option value="-">Bitte auswählen</option>
                          {prioList}
                      </select>
                      <div className="-mt-8 pointer-events-none float-right inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>
                </div>   

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Aufgenommen von:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.createdBy} onChange={this.handleChange} name="createdBy" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Verantwortlich:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeItem.responsible} onChange={this.handleChange} name="responsible" className={infoInputClasses} type="text" placeholder=""></input>
                  </div>
                </div>           

              </div>
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Notizen
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.noteEditable} onClick={this.handleNoteEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.noteEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                <textarea rows="15" readOnly={this.state.noteEditable ? false : "readonly"} value={this.state.activeItem.notes} onChange={this.handleChange} name="notes" className={noteInputClasses} ></textarea>

                </div>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList currentBuildingId={this.state.currentBuildingId} folderId={this.state.activeItem.gDriveFolderId}></DocumentList>
              </div>
            </div>


            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <History currentBuildingId={this.state.currentBuildingId} events={this.state.activeItem.events} addFunction={this.addEvent} deleteFunction={this.deleteEvent} editFunction={this.editEvent}></History>
              </div>
            </div>

            <div className="flex mb-10 float-right">
              <div className="">
                    <button onClick={this.deleteItem} className="mr-4 text-sm mt-4 px-4 ml-4 py-2 leading-none border rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700">Mangel löschen</button>
                </div>
            </div>

          </div>


            </div>
          </div>

         </div>
		
        <Sidebar active="defects" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}