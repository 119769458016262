import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Notification from '../components/Notification';


import { 
  dbGetEntries } from '../helpers/db_entry';


export default class Communication extends Component {

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;

    this.state = {
      page: "communication",
      currentBuildingId: id,
      tenants: [],
      messageType: "",
      messageTitle: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.selectAllTenants = this.selectAllTenants.bind(this);
  }

  componentDidMount() {    
    //Lade alle verfügbaren Mieter
    dbGetEntries(this.state.currentBuildingId, "tenants", function(tenants) {
      console.log(tenants)
      this.setState({ 
        tenants: tenants
      });
    }.bind(this));
  }

  selectAllTenants() {
    var newTenants = this.state.tenants;
    newTenants.forEach (function (tenant) {
      tenant.selected = true;
    });

    this.setState({
      tenants: newTenants
    });
  }

  handleChange(event) {
    if (event.target.type === "checkbox") {
      var newTenants = this.state.tenants;
      newTenants.forEach (function (tenant) {
        if (tenant.id === event.target.name) {
          tenant.selected = !tenant.selected;
        }
      });

      this.setState({
        tenants: newTenants
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  }

  render() {

    
    var tempTenants = this.state.tenants;
    tempTenants.sort(function(a, b) {
        return a.title >= b.title
    });
    var tenantList = tempTenants.map(function(tenant) {    
      return (         
        <label className="block float-left mr-8 mb-4" key={tenant.id}>
          <input className="mr-2 leading-tight" type="checkbox" name={tenant.id} checked={tenant.selected || false}  onChange={this.handleChange}></input>
          <span className="">
            {tenant.title}
          </span>
        </label>      
        )}.bind(this));  

    var addresses = [];
    var that = this;
    this.state.tenants.forEach (function (tenant) {
      if (tenant.selected && tenant.contacts && tenant.contacts.length > 0) {
        for (var i=0; i<tenant.contacts.length; i++) {
          if (that.state.messageType === "info" && tenant.contacts[i].isInfoContact) {
            addresses.push(tenant.contacts[i].email);
          } else if (that.state.messageType === "emergency" && tenant.contacts[i].isEmergencyContact) {
            addresses.push(tenant.contacts[i].email);
          } else if  (that.state.messageType === "finance" && tenant.contacts[i].isFinanceContact) {
            addresses.push(tenant.contacts[i].email);
          }
        }
      }
    });

    var sendButtonHref = "mailto:";
    for (var i=0; i<addresses.length; i++) {
      sendButtonHref = sendButtonHref + addresses[i] + ";"
    }
    sendButtonHref = sendButtonHref + "?subject=" + this.state.messageTitle;

    var sendButtonClasses = addresses.length === 0 ? "hidden" : "mr-4 text-sm mt-4 px-4 py-2 leading-none border rounded text-main-200 border-main-200 hover:border-transparent hover:text-white hover:bg-main-200";

    return (
      <div className="flex md:flex-row-reverse flex-wrap">
        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Kommunikation mit Mietern" description="Versendet keine Emails, sondern öffnet Outlook mit den entsprechenden Adressen eingetragen."></Navbar>
           <div className="p-2">
            <div className="flex">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Neue Nachricht</h3>
                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/6">
                    <label className="block mb-1 md:mb-0 pr-4">Typ:</label>
                  </div>
                  <div className="md:w-1/4">
                  <div>
                      <select onChange={this.handleChange} name="messageType" value={this.state.messageType} className="float-left appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200">
                          <option value="-">Bitte auswählen</option>
                          <option value="info">Info</option>
                          <option value="emergency">Notfall</option>
                          <option value="finance">Buchhaltung</option>
                      </select>
                      <div className="-mt-8 pointer-events-none float-right inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="md:flex mb-2 items-start">
                  <div className="md:w-1/6">
                    <label className="block mb-1 md:mb-0 pr-4">Empfänger:</label>
                  </div>
                  <div className="md:w-5/6">
                    
                      {tenantList}
                    <div className="flex mt-4 w-full text-sm text-gray-600 italic cursor-pointer mb-8" onClick={this.selectAllTenants}>Alle auswählen</div>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-8">
                  <div className="md:w-1/6">
                    <label className="block mb-1 md:mb-0 pr-4">Betreff:</label>
                  </div>
                  <div className="md:w-1/4">
                    <input value={this.state.messageTitle} onChange={this.handleChange} name="messageTitle" className="appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Mein Betreff"></input>
                  </div>
                </div>

                <div className="md:flex mb-2">
                  <div className="md:w-1/6">
                  </div>
                  <div className="md:w-2/4">
                  <a href={sendButtonHref} className={sendButtonClasses}>In Outlook öffnen</a>
                  </div>
                </div>

              </div>
            </div>
           </div>
         </div>
		 
        <Sidebar active="communication" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}