import { auth } from "../services/firebase";

export function signup(email, password) {
    return auth().createUserWithEmailAndPassword(email, password);
}
  
export function signin(email, password) {
    return auth().signInWithEmailAndPassword(email, password);
}  
 
export function signout() {
    return auth().signOut().then(function() {
        window.location.href = '/login';
      }).catch(function(error) {
       console.log(error)
      });
}   

export function getRoleForThisBuilding(buildingId, callback) {
    auth().currentUser.getIdTokenResult().then((idTokenResult) => {
        callback(idTokenResult.claims[buildingId]);
  }).catch((error) => {
    console.log(error);
    callback(undefined);
  });

}

export async function getRoleForThisBuildingAsync(buildingId) {
    var idTokenResult = await auth().currentUser.getIdTokenResult();
    return idTokenResult.claims[buildingId];
}