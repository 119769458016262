import {db, storage, auth, functions} from '../services/firebase';

/*

 NEUER GDRIVE PLAN:

 1. Für jedes Gebäude müssen die STandard Ordner manuell erstellt werden in GDrive und die IDs in Firebase eingetargen werden
 2. Wenn ein neuer "Entry" (Mieter, Anlagen, ...) erstellt wird, wird ein neuer Ordner in GDrive in dem ensptrechenden Überordner erstellt und diese ID in Firebase zu dem Entry gespeichert
 3. Wenn ein Entry geöffnet werden alle Files aus diesem Ordner aus GDrive geladen und angezeigt
 3. Zusätzlich ist ein Button "Dateien verwalten", der direkt zu dem Ordner in Drive verlinkt. Nur in GDrive können Dateien hochgeladen werden
 4. Bui building_allgemein und building_finanzen läuft es genau gleich wie bei jedem Entry, nur dass hier keine ORdner erstellt werden müssen


*/


export function createGDriveFolder(currentBuildingId, page, name, callback) {
    console.log("db call: createGDriveFolder");

    var callable = functions.httpsCallable('createFolder');
    callable({ "buildingId": currentBuildingId, "page": page, "name": name}).then(function(result) {
        console.log("result: " + JSON.stringify(result))
        console.log(result)
        callback(result.data);
    }).catch(function(error) {
        var errorMessage = "Error";

        if (error) {
            if (error.message) {
                errorMessage = error.message;
            } else {
                errorMessage = JSON.stringify(error);
            }
        }
        callback(errorMessage, undefined);
    });
}

export function listGDriveFiles(currentBuildingId, folderId, callback) {
    console.log("db call: listgDriveFiles: " + currentBuildingId + " - " + folderId);

    var callable = functions.httpsCallable('getFiles');
    callable({ "buildingId": currentBuildingId, "folder": folderId}).then(function(result) {
        callback(undefined, result.data);
    }).catch(function(error) {
        var errorMessage = "Error";

        if (error) {
            if (error.message) {
                errorMessage = error.message;
            } else {
                errorMessage = JSON.stringify(error);
            }
        }
        console.log("error: " + JSON.stringify(error));
        callback(errorMessage, undefined);
    });
}
