import React, { Component } from 'react';
import { IconPickerItem } from 'react-fa-icon-picker';

export default class EntryList extends Component {

    constructor(props) {
        super(props);

        var activeEntry = "";
        if (props.entries && props.entries.length > 0) {
            activeEntry = props.entries[0];
        }
        this.state = {
            active: activeEntry,
            dialogOpen: false,
            description: ""
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        if (this.state.active && this.state.active.length > 0) {
            this.props.onActiveChanged(this.state.active);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected) {
            if (!prevProps || prevProps.selected.id !== this.props.selected.id) {
                this.setState({
                    active: this.props.selected.title
                });
            }
        }
      }

    handleClick(event) {
        this.setState({
            active: event.target.name
        });

        for (var i=0; i<this.props.entries.length; i++) {
            if (this.props.entries[i].title === event.target.name) {
                this.props.onActiveChanged(this.props.entries[i]);
            }
        }
    }

    handleChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
      }

    openDialog() {
        this.setState({
            dialogOpen: true
        });
    }

    save() {
        this.props.onEntryAdded(this.state.description);
        this.closeDialog();
    }

    closeDialog() {
        this.setState({
            dialogOpen: false,
            description: ""
        });
    }

  render() {
    var self = this;
    if (!this.props.entries) {
        this.props.entries = [];
    }

    var sortedEntries = this.props.entries;

    sortedEntries.sort(function(a, b) {
        return a.title.localeCompare(b.title)
    });


    var countRecurring = 0, countNonRecurring = 0;
    var recurringList = sortedEntries.map(function(entry) {    
        if (!entry.recurring) {
            return "";
        } 
        countRecurring++;
        var classNames = "mb-4 p-2 w-full border-l-2 border-main-200 focus:border-2 hover:bg-gray-200 text-left font-medium";
        if (entry.title === self.state.active) {
            classNames = "mb-4 p-2 w-full border-l-2 border-main-200 focus:border-2 text-left bg-gray-200 font-bold";
        }

        return (<div key={entry.title}>
           
            <button name={entry.title} onClick={self.handleClick} className={classNames}> 
                <IconPickerItem containerStyles={{padding: "0px", display: "inline-block", marginBottom: "-3px", marginRight: "8px"}} icon={entry.icon} size={18} color="#000"/> 
                {entry.title}
            </button>

        </div>                
    )});

    var nonRecurringList = sortedEntries.map(function(entry) {   
        if (entry.recurring) {
            return "";
        } 
        countNonRecurring++
        var classNames = "mb-4 p-2 w-full border-l-2 border-main-200 focus:border-2 hover:bg-gray-200 text-left font-medium";
        if (entry.title === self.state.active) {
            classNames = "mb-4 p-2 w-full border-l-2 border-main-200 focus:border-2 text-left bg-gray-200 font-bold";
        }

        return (<div key={entry.title}>
           
            <button name={entry.title} onClick={self.handleClick} className={classNames}> 
                <IconPickerItem containerStyles={{padding: "0px", display: "inline-block", marginBottom: "-3px", marginRight: "8px"}} icon={entry.icon} size={18} color="#000"/> 
                {entry.title}
            </button>

        </div>                
    )});

    var dividerClasses = countRecurring === 0 || countNonRecurring === 0 ? "hidden" : "mb-2";

    var modalClasses = "modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center";
    if (this.state.dialogOpen) {
        modalClasses = "modal fixed w-full h-full top-0 left-0 flex items-center justify-center";
    }

    return (
        <div className="shadow-md relative">
            <div className="bg-white w-full h-full m-1 p-2">
                <button onClick={this.openDialog} className="inline-block text-sm px-4 ml-4 mb-6 mt-4 py-2 leading-none border rounded text-main-100 border-main-100 hover:border-transparent hover:text-white hover:bg-main-200">{this.props.title}</button>
                <div>
                    {recurringList}
                </div>
                <div className={dividerClasses}>____________</div>
                <div>
                    {nonRecurringList}
                </div>
            </div>

            <div className={modalClasses}>
                <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                
                <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center pb-3">
                    <p className="text-lg font-bold">{this.props.title}</p>
                    </div>
            
                    <div className="mb-4">
                        <label className="block mb-2">Bezeichnung</label>
                        <input onChange={this.handleChange} name="description" value={this.state.description} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Firma Gärtner"></input>
                    </div>
            
                    <div className="flex justify-end pt-2">
                    <button className="px-4 bg-transparent p-3 rounded text-main-100 hover:bg-main-200 hover:text-white mr-2" onClick={this.closeDialog}>Abbrechen</button>
                    <button className="modal-close px-4 bg-main-100 p-3 rounded text-white hover:bg-main-200" onClick={this.save}>Speichern</button>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
    )
  }
}