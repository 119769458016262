import React, { Component } from 'react';
import { signout } from "../helpers/auth";

export default class Navbar extends Component {

  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    if (window.confirm("Willst du dich wirklich ausloggen?")) {
      signout();
    }
  }

  render() {

    return (
        <nav className="flex items-center justify-between flex-wrap bg-white p-6 shadow">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <div className="font-semibold text-xl tracking-tight text-main-200">{this.props.title}
            <div className="text-sm tracking-tight text-gray-500">{this.props.description}</div>
          </div>
        </div>

        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow">
       
          </div>
          <div>
             
            <button className="inline-block text-sm px-4 ml-4 py-2 leading-none border rounded text-main-100 border-main-100 hover:border-transparent hover:text-white hover:bg-main-200 mt-4 lg:mt-0" onClick={this.logout}>Ausloggen</button>

          </div>
        </div>
      </nav>
    )
  }
}