import {db, storage} from '../services/firebase';


export function dbGetCurrentTasks(currentBuildingId, callback) {
    console.log("db call: dbGetCurrentTasks");


    var nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    var dd = String(nextWeek.getDate()).padStart(2, '0');
    var mm = String(nextWeek.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = nextWeek.getFullYear();

    var dateString = yyyy + "-" + mm + "-" + dd;

    var result = [];
    db.collection("buildings").doc(currentBuildingId).collection("maintenance")
    .where("dueDate", "<=", dateString)
    .where("statusOpen", "==", true)
    .get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var obj = doc.data();
            obj.id = doc.id;
            result.push(obj);
        });

        callback(result);
    });
}

export function dbGetFutureTasks(currentBuildingId, callback) {
    console.log("db call: dbGetFutureOnceTasks");


    var nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    var dd = String(nextWeek.getDate()).padStart(2, '0');
    var mm = String(nextWeek.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = nextWeek.getFullYear();

    var dateString = yyyy + "-" + mm + "-" + dd;

    var result = [];
    db.collection("buildings").doc(currentBuildingId).collection("maintenance")
    .where("dueDate", ">", dateString)
    .where("statusOpen", "==", true)
    .get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var obj = doc.data();
            obj.id = doc.id;
            result.push(obj);
        });

        callback(result);
    });
}
