import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Index extends Component {
  render() {
    return (
      <div className="flex h-screen bg-main-200">
        <div className="max-w-xl w-full lg:max-w-full lg:flex m-auto justify-center">
        <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{backgroundImage: "url('/img/skyline-front.jpg')"}} title="Woman holding a mug">
        </div>
        <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className="text-gray-900 font-bold text-xl mb-2">Willkommen bei der SkylineOffice Verwaltung</div>
          </div>
          <div className="flex items-center">
            <Link to="/login">
              <button className="bg-main-100 hover:bg-main-200 text-white py-2 px-4 rounded mr-1">Login</button>
            </Link>
            <Link to="/signup">
              <button className="bg-transparent hover:bg-main-200 text-main-100 font-semibold hover:text-white py-2 px-4 border border-main-100 hover:border-transparent rounded">Account erstellen</button>
            </Link>
          </div>
        </div>
      </div>
      </div>
    )
  }
}