import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBN4GfIHJfhHhS6GYiU48MvJmLY2Qjib7w",
    authDomain: "skylineoffice-3db56.firebaseapp.com",
    databaseURL: "https://skylineoffice-3db56.firebaseio.com",
    projectId: "skylineoffice-3db56",
    storageBucket: "skylineoffice-3db56.appspot.com",
    messagingSenderId: "187239201751",
    appId: "1:187239201751:web:2426e236ceea03f67ea992"
  };

  firebase.initializeApp(firebaseConfig);
  export const auth = firebase.auth;
  export const db = firebase.firestore();
  export const storage = firebase.storage().ref();
  export const functions = firebase.functions();