import {db, functions} from '../services/firebase';



export function dbAddBuilding(title, NotificationObject, callback) {
    console.log("db call: dbAddBuilding: " + title);
    db.collection("buildings").add({title: title, admintitle: title})
    .then(function() {
        NotificationObject.show("Erfolg", "Gebäude gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        console.log(error)
        NotificationObject.show("Fehler", "Gebäude konnte nicht gespeichert werden.", false, true, 3000);
    });
}

export function dbGetAllBuildings(callback) {
    var callable = functions.httpsCallable('getAllBuildings');
    callable({ }).then(function(result) {
        callback(result);
    }).catch(function(error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
      
        console.log("error code: " + code + " error message: " + message + " error details: " + details)
    });
}


export function dbUpdateRole(buildingId, userId, newRole, NotificationObject, callback) {
    console.log("db call: dbUpdateRole");

    var callable = functions.httpsCallable('changeRole');
    callable({ "building": buildingId, "user": userId, "role": newRole }).then(function(result) {
        callback(result);
    }).catch(function(error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
      
        console.log("error code: " + code + " error message: " + message + " error details: " + details)
    });
}

export function dbGetAllUsers(callback) {
    var callable = functions.httpsCallable('getAllUsers');
    callable({ }).then(function(result) {
        callback(result);
    }).catch(function(error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
      
        console.log("error code: " + code + " error message: " + message + " error details: " + details)
    });
}


export function dbAddUserToBuilding(buildingId, userId, callback) {
    var callable = functions.httpsCallable('addUserToBuilding');
    callable({ "building": buildingId, "user": userId }).then(function(result) {
        callback(result);
    }).catch(function(error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
      
        console.log("error code: " + code + " error message: " + message + " error details: " + details)
    });
}


export function dbRemoveUserFromBuilding(buildingId, userId, callback) {
    var callable = functions.httpsCallable('removeUserFromBuilding');
    callable({ "building": buildingId, "user": userId }).then(function(result) {
        callback(result);
    }).catch(function(error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
      
        console.log("error code: " + code + " error message: " + message + " error details: " + details)
    });
}