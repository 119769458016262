import React, { Component } from 'react';


const emptyTicket = {
    title: "",
    createDate: new Date(),
    description: "",
    priority: "",
    category: "",
    color: "",
    state: "open",
    responsible: ""
  }

export default class TicketDialog extends Component {

constructor(props) {
    super(props);
    this.state = {
        open: false,
        saveCallback: undefined,
        deleteCallback: undefined,
        ticket: emptyTicket
    };

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
}

close() {
    this.setState({ 
        open: false,
        saveCallback: undefined,
        deleteCallback: undefined
      });
}

save() {
    this.state.saveCallback(this.state.ticket);
    this.close();
}

delete() {
    if (window.confirm("Soll das Ticket wirklich gelöscht werden?")) {
        this.state.deleteCallback(this.state.ticket.id);
        this.close();
    }
}

open(ticket, saveCallback, deleteCallback) {
    if (ticket === undefined) {
        ticket = emptyTicket;
    }

    this.setState({ 
        open: true,
        saveCallback: saveCallback,
        deleteCallback: deleteCallback,
        ticket: ticket
      });
}

handleChange(event) {
    var ticket = this.state.ticket;
    ticket[event.target.name] = event.target.value;
    this.setState({
        ticket: ticket
    });
  }

componentDidMount() {
    this.props.onRef(this)
}
componentWillUnmount() {
    this.props.onRef(undefined)
}


  render() {

    var modalClasses = "z-10 modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center";
    if (this.state.open) {
        modalClasses = "z-10 modal fixed w-full h-full top-0 left-0 flex items-center justify-center";
    }

    var deleteButtonClasses = "hidden px-4 bg-transparent p-3 rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700 mr-2";
    if (this.state.deleteCallback) {
        deleteButtonClasses = "px-4 bg-transparent p-3 rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700 mr-2";
    }

    return (
        <div className={modalClasses}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-scroll max-h-3/4">

          <div className="modal-content py-4 text-left px-6">
            <div className="flex justify-between items-center pb-3">
              <p className="text-lg font-bold">Ticket bearbeiten</p>
            </div>
    
            <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                    <label className="block mb-2">Titel</label>
                    <input onChange={this.handleChange} name="title" value={this.state.ticket.title} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Rasen mähen"></input>
                </div>
                <div className="md:w-1/2 w-full p-2">
                    <label className="block mb-2">Verantwortlich</label>
                    <input onChange={this.handleChange} name="responsible" value={this.state.ticket.responsible} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Max Mustermann"></input>
                </div>
             </div>

            <div className="mb-2 p-2">
                <label className="block mb-2">Beschreibung</label>
                <textarea onChange={this.handleChange} name="description" value={this.state.ticket.description} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="textarea" size="3" placeholder="Hinter dem parkplatz muss der Rasen gemäht werden"></textarea>
            </div>

            <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                    <label className="block mb-2">Kategorie</label>
                    <input onChange={this.handleChange} name="category" value={this.state.ticket.category} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Elektro"></input>
                </div>
                <div className="md:w-1/2 w-full p-2">
                    <label className="block mb-2">Farbe</label>
                    <div className="relative">
                        <select onChange={this.handleChange} name="color" value={this.state.ticket.color} className="block appearance-none w-full bg-gray-200 border-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-main-200" id="grid-state">
                            <option value="-">Bitte auswählen</option>
                            <option value="#e72121">Rot</option>
                            <option value="#eb8f00">Orange</option>
                            <option value="#39b600">Grün</option>
                            <option value="#002eb6">Blau</option>
                            <option value="#1b1b1b">Schwarz</option>
                            <option value="#9f0095">Lila</option>
                            <option value="#f2cc00">Gelb</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
                </div>
             </div>

             
            <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                    <label className="block mb-2">Priorität</label>
                    <div className="relative">
                        <select onChange={this.handleChange} name="priority" value={this.state.ticket.priority} className="block appearance-none w-full bg-gray-200 border-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-main-200">
                            <option value="-">Bitte auswählen</option>
                            <option value="low">Niedrig</option>
                            <option value="normal">Normal</option>
                            <option value="high">Hoch</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 w-full p-2">
                    <label className="block mb-2">Status</label>
                    <div className="relative">
                        <select onChange={this.handleChange} name="state" value={this.state.ticket.state} className="block appearance-none w-full bg-gray-200 border-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-main-200">
                            <option value="open">Offen</option>
                            <option value="progress">In Bearbeitung</option>
                            <option value="done">Abgeschlossen</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
                </div>
             </div>

    
            <div className="flex justify-end pt-2">
                <button className={deleteButtonClasses} onClick={this.delete}>Löschen</button>
                <button className="px-4 bg-transparent p-3 rounded text-main-100 hover:bg-main-200 hover:text-white mr-2" onClick={this.close}>Abbrechen</button>
                <button className="modal-close px-4 bg-main-100 p-3 rounded text-white hover:bg-main-200" onClick={this.save}>Speichern</button>
            </div>
            
          </div>
        </div>
      </div>
    )
  }

}