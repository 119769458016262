import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFilePdf, faFile, faFileExcel, faFileImage, faFilePowerpoint, faFileWord, faEdit } from '@fortawesome/free-solid-svg-icons';
import Notification from '../components/Notification';

import { 
  dbGetEntryDocuments } from '../helpers/db_entry';


export default class DocumentList extends Component {

  constructor(props) {
      super(props);
      this.state = {
        message: "Lade Dateien...",
        files: []
      };
  }

  componentDidMount() {
      if (this.props.folderId !== undefined) {
        dbGetEntryDocuments(this.props.currentBuildingId, this.props.folderId, function(error, files) {
          this.setState({
            message: error,
            files: files
          });
        }.bind(this));
      } else {
        this.setState({
          message: "Ungültige Google Drive Ordner ID.",
          files: []
        });
      }
    }

  componentDidUpdate(prevProps) {
    if (prevProps === undefined || prevProps.folderId !== this.props.folderId) {
      this.setState({
        message: "Lade Dateien...",
        files: []
      });

      dbGetEntryDocuments(this.props.currentBuildingId, this.props.folderId, function(error, files) {
        this.setState({
          message: error,
          files: files
        });
      }.bind(this));
    } 
  }


  render() {    
    var fileList = [];
    if (this.state.files) {
      this.state.files.sort(function(a, b){return a.name >= b.name});

      fileList = this.state.files.map(function(document) {    
        var icon = faFile;
        if (document.name && document.name.endsWith(".xlsx")) {
          icon = faFileExcel; 
        } else if (document.name && document.name.endsWith(".pptx")) {
          icon = faFilePowerpoint;
        } else if (document.name && document.name.endsWith(".docx")) {
          icon = faFileWord;
        }else if (document.mimeType === "application/pdf") {
          icon = faFilePdf;
        } else if (document.mimeType === "image/png") {
          icon = faFileImage;
        } else if (document.mimeType === "image/jpeg") {
          icon = faFileImage;
        }
  
        return (                
          <div className="flex flex-wrap -mx-3 mb-2" key={document.id}>
            <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0">
            <FontAwesomeIcon icon={icon} />
            </div>
  
            <div className="w-full md:w-10/12 px-3 mb-6 md:mb-0">
              <a href={document.link} target="_blank" rel="noopener noreferrer">{document.name}</a>
            </div>
  
            <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0 items-end">
              <a href={document.link} target="_blank" rel="noopener noreferrer" className="align-bottom ml-4"><FontAwesomeIcon icon={faEdit} /></a>
            </div>
          </div>
          )});
    }


    var title = this.props.title ? this.props.title : "Dokumente";
    var gDriveLink = "https://drive.google.com/drive/u/0/folders/" + this.props.folderId;

    return (
        <div>
            <Notification onRef={ref => (this._notification = ref)}></Notification>
            <h3 className="mb-4 font-bold">{title} <a className="text-xs text-main-100 mt-3" target="_blank" rel="noopener noreferrer" href={gDriveLink}>Link zum Ordner</a></h3>

            <div>
              {this.state.message}
            </div>

            <div>
              {fileList}
            </div> 
        </div>
    )
  }
}