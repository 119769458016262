import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import EventDialog from './EventDialog';

export default class History extends Component {

    constructor(props) {
        super(props);

        this.state = {
            events: []
        };

        this.openAddEvent = this.openAddEvent.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentWillReceiveProps(props) {
      var events = props.events;
      if (!events) {
        events = [];
      }
      this.setState({
        events:  events
      });
    }

    openAddEvent() {
      var self = this;
      self._addEventDialog.open(undefined, function(title, date, description) {
        var newEvent = {
          title: title,
          date: date,
          description: description
        };
  

        self.props.addFunction(newEvent);
      })
    }

    editEvent(index) {      
      var self = this;
      self._addEventDialog.open(self.state.events[index], function(title, date, description) {
          self.state.events[index].title = title;
          self.state.events[index].date = date;
          self.state.events[index].description = description;

          self.setState({ 
            events: self.state.events
          });

          self.props.editFunction(self.state.events);
      })
    }


    deleteEvent(index, title) {
      if (window.confirm("Soll dieses Ereignis wirklich gelöscht werden? (" + title + ")")) {
        this.props.deleteFunction(index);
      }
    }

  render() {
    this.state.events.sort(function(a, b){return a.date > b.date});
    var eventList = this.state.events.map(function(event, index) {


      return (        
        <li className="mb-2" key={index}>
          <div className="flex items-center mb-1">
            <div className="bg-main-200 rounded-full h-8 w-8"></div>
            <div className="flex-1 ml-4">{event.title} <span className="text-sm text-gray-600">{event.date}</span></div>
            <button onClick={() => this.editEvent(index, event.title)} className="align-bottom mt-1 ml-4"><FontAwesomeIcon icon={faEdit} /></button>
            <button onClick={() => this.deleteEvent(index, event.title)} className="align-bottom mt-1 ml-4"><FontAwesomeIcon icon={faTimes} /></button>
          </div>
          <div className="ml-12 text-gray-600">
            {event.description}
          </div>
        </li>        
        )}.bind(this));

    return (
        <div>
            <EventDialog onRef={ref => (this._addEventDialog = ref)}></EventDialog>

            <h3 className="mb-8 font-bold">Ereignisse <button className="text-xs text-main-100 ml-3" onClick={this.openAddEvent}>+ Hinzufügen</button></h3>
            <div className="m-8 relative z-0">
              <div className="border-r-2 border-main-200 h-full absolute top-0 ml-4"></div>
              <ul className="list-none m-0 p-0">
                {eventList}
              </ul>
            </div>
        </div>
    )
  }
}