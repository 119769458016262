import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signup } from '../helpers/auth';

export default class SignUp extends Component {
  constructor(props) {
      super(props);
      this.state = {
        error: null,
        email: '',
        password: '',
        password2: '',
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  
    async handleSubmit(event) {
      event.preventDefault();

      if (this.state.password !== this.state.password2) {
        this.setState({ error: 'Passwörter stimmen nicht überein' });
        return;
      }

      this.setState({ error: '' });
      try {
        await signup(this.state.email, this.state.password);
      } catch (error) {
        this.setState({ error: error.message });
      }
    }
      
  render() {
    return (
      <div class="flex h-screen bg-main-200">
      <div class="max-w-xl w-full lg:max-w-full lg:flex m-auto justify-center">
      <div class="border border-gray-400 lg:border lg:border-gray-400 bg-white rounded lg:rounded p-4 flex flex-col justify-between leading-normal">
        <div class="mb-4">
          <div class="text-main-200 font-bold text-xl">Account erstellen</div>
        </div>
        <div class="flex items-center">
        <form
          autoComplete="off"
          onSubmit={this.handleSubmit}>

          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
              Email
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" name="email" placeholder="email@test.com" onChange={this.handleChange} value={this.state.email}></input>
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
              Passwort
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" name="password" type="password" placeholder="******************" onChange={this.handleChange} value={this.state.password}></input>
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="password2">
              Passwort wiederholen
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password2" name="password2" type="password" placeholder="******************" onChange={this.handleChange} value={this.state.password2}></input>
          </div>

          <div>
            {this.state.error ? (
               <p class="text-red-500 text-xs italic mb-4">{this.state.error}</p>
            ) : null}
            <button class="bg-main-100 hover:bg-main-200 text-white py-2 px-4 rounded mb-3" type="submit">Erstellen</button>
          </div>
          <hr />
          <p>
            Schon ein Account vorhanden? <Link to="/login" class="text-main-100">Hier einloggen</Link>
          </p>
        </form>
        </div>
      </div>
    </div>
    </div>
    )
  }
}