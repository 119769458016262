import React, { Component } from 'react';

export default class NoMatch extends Component {
  render() {
    return (
      <div class="flex h-screen bg-main-100">
      <div class="max-w-xl w-full lg:max-w-full lg:flex m-auto justify-center">
      <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{backgroundImage: "url('/img/skyline-front.jpg')"}} title="Woman holding a mug">
      </div>
      <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div class="mb-8">
          <div class="text-gray-900 font-bold text-xl mb-2">404 - Seite nicht gefunden</div>
        </div>
      </div>
    </div>
    </div>
    )
  }
}