import React, { Component } from "react";
import { Link } from "react-router-dom";
import { signin } from "../helpers/auth";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      password: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await signin(this.state.email, this.state.password);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {
    return (
      <div className="flex h-screen bg-main-200">
      <div className="max-w-xl w-full lg:max-w-full lg:flex m-auto justify-center">
      <div className="border border-gray-400 lg:border lg:border-gray-400 bg-white rounded lg:rounded p-4 flex flex-col justify-between leading-normal">
        <div className="mb-4">
          <div className="text-main-200 font-bold text-xl">Login</div>
        </div>
        <div className="flex items-center">
        <form
          autoComplete="off"
          onSubmit={this.handleSubmit}>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" name="email" placeholder="email@test.com" onChange={this.handleChange} value={this.state.email}></input>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Passwort
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" name="password" type="password" placeholder="******************" onChange={this.handleChange} value={this.state.password}></input>
          </div>

          <div>
            {this.state.error ? (
               <p className="text-red-500 text-xs italic mb-4">{this.state.error}</p>
            ) : null}
            <button className="bg-main-100 hover:bg-main-200 text-white py-2 px-4 rounded mb-3" type="submit">Login</button>
          </div>
          <hr />
          <p>
            Noch keinen Account? <Link to="/signup" className="text-main-100">Hier erstellen</Link>
          </p>
        </form>
        </div>
      </div>
    </div>
    </div>
    );
  }
}