import {db, storage, auth, functions} from '../services/firebase';

export function updateBuildingInfos(currentBuildingId, data, NotificationObject) {
    console.log("db call: updateBuildingInfos");
    NotificationObject.show("Bitte warten", "Allgemeine Informationen werden gespeichert...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).set({
        title: data.title || "",
        street: data.street || "",
        city: data.city || "",
        rentalArea: data.rentalArea || "",
        year: data.year || "",
        notes: data.notes || ""
    }, {merge: true})
    .then(function() {
        NotificationObject.show("Erfolg", "Allgemeine Informationen gespeichert.", false, false, 3000);
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Allgemeine Informationen konnten nicht gespeichert werden.", false, true, 3000);
    });
}

export function getBuildingInfos(currentBuildingId, callback) {
    console.log("db call: getBuildingInfos");
    var docRef = db.collection("buildings").doc(currentBuildingId);

    docRef.get().then(function(doc) {
        if (doc.exists) {
            if (callback) {
                callback(doc.data());
            }
        } else {
            console.log("No such document!");
        }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
}

export function dbDeleteTenant(currentBuildingId, tenantId, NotificationObject, callback) {
    console.log("db call: dbDeleteTenant");

    storage.child(currentBuildingId + '/tenants/' + tenantId).listAll().then(function(res) {
        var count = res.items ? res.items.length : 0;
        res.items.forEach(function(itemRef) {
            itemRef.delete().then(function() {
                count++;

                if (count >= res.items.length) {
                    deleteTenantData(currentBuildingId, tenantId, NotificationObject, callback);
                }

              }).catch(function(error) {
                NotificationObject.show("Fehler", "Mieter konnte nicht gelöscht werden.", false, true, 3000);
              });
              
        });

        if (count === 0) {
            deleteTenantData(currentBuildingId, tenantId, NotificationObject, callback);
        }
      }).catch(function(error) {
            console.log(error)
            NotificationObject.show("Fehler", "Mieter konnte nicht gelöscht werden.", false, true, 3000);
      }).catch(function(error) {
            NotificationObject.show("Fehler", "Mieter konnte nicht gelöscht werden.", false, true, 3000);
      });
}

function deleteTenantData(currentBuildingId, tenantId, NotificationObject, callback) {
    console.log("db call: deleteTenantData");
    db.collection("buildings").doc(currentBuildingId).collection("tenants").doc(tenantId).delete().then(function() {
        NotificationObject.show("Erfolg", "Mieter wurde gelöscht.", false, false, 3000);
        callback();
    }).catch(function(error) {
        NotificationObject.show("Fehler", "Mieter konnte nicht gelöscht werden.", false, true, 3000);
    });
}


export function dbAddTenantReminder(currentBuildingId, tenantId, data, NotificationObject, callback) {
    console.log("db call: dbAddTenantReminder");
    db.collection("buildings").doc(currentBuildingId).collection("tenants").doc(tenantId).collection("reminders").add(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Erinnerung gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Erinnerung konnte nicht gespeichert werden.", false, true, 3000);
    });
}


export function dbEditTenantReminder(currentBuildingId, tenantId, data, NotificationObject, callback) {
    console.log("db call: dbEditTenantReminder");
    var docId = data.id;

    db.collection("buildings").doc(currentBuildingId).collection("tenants").doc(tenantId).collection("reminders").doc(docId).update(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Erinnerung gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Erinnerung konnte nicht gespeichert werden.", false, true, 3000);
    });
}

export function dbGetTenantReminders(currentBuildingId, tenantId, callback) {
    console.log("db call: dbGetTenantReminders");
    var result = [];
    db.collection("buildings").doc(currentBuildingId).collection("tenants").doc(tenantId).collection("reminders").get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var obj = doc.data();
            obj.id = doc.id;
            result.push(obj);
        });

        callback(result);
    });
}

export function dbDeleteTenantReminder(currentBuildingId, tenantId, id, NotificationObject, callback) {
    console.log("db call: dbDeleteTenantReminder");
    NotificationObject.show("Bitte warten", "Erinnerung wird gelöscht...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).collection("tenants").doc(tenantId).collection("reminders").doc(id).delete().then(function() {
        NotificationObject.show("Erfolg", "Erinnerung wurde gelöscht.", false, false, 3000);
        callback();
    }).catch(function(error) {
        NotificationObject.show("Fehler", "Erinnerung konnte nicht gelöscht werden.", false, true, 3000);
    });
}









export function dbAddService(currentBuildingId, data, NotificationObject, callback) {
    console.log("db call: dbAddService");
    db.collection("buildings").doc(currentBuildingId).collection("services").add(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Eintrag gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Eintrag konnte nicht gespeichert werden.", false, true, 3000);
    });
}


export function dbDeleteService(currentBuildingId, id, NotificationObject, callback) {
    console.log("db call: dbDeleteService");

    storage.child(currentBuildingId + '/services/' + id).listAll().then(function(res) {
        var count = res.items ? res.items.length : 0;
        res.items.forEach(function(itemRef) {
            itemRef.delete().then(function() {
                count++;

                if (count >= res.items.length) {
                    deleteServiceData(currentBuildingId, id, NotificationObject, callback);
                }

              }).catch(function(error) {
                NotificationObject.show("Fehler", "Eintrag konnte nicht gelöscht werden.", false, true, 3000);
              });
              
        });

        if (count === 0) {
            deleteServiceData(currentBuildingId, id, NotificationObject, callback);
        }
      }).catch(function(error) {
            console.log(error)
            NotificationObject.show("Fehler", "Eintrag konnte nicht gelöscht werden.", false, true, 3000);
      }).catch(function(error) {
            NotificationObject.show("Fehler", "Eintrag konnte nicht gelöscht werden.", false, true, 3000);
      });
}

function deleteServiceData(currentBuildingId, id, NotificationObject, callback) {
    console.log("db call: deleteServiceData");
    db.collection("buildings").doc(currentBuildingId).collection("services").doc(id).delete().then(function() {
        NotificationObject.show("Erfolg", "Eintrag wurde gelöscht.", false, false, 3000);
        callback();
    }).catch(function(error) {
        NotificationObject.show("Fehler", "Eintrag konnte nicht gelöscht werden.", false, true, 3000);
    });
}


export function dbAddServiceReminder(currentBuildingId, serviceId, data, NotificationObject, callback) {
    console.log("db call: dbAddServiceReminder");
    db.collection("buildings").doc(currentBuildingId).collection("services").doc(serviceId).collection("reminders").add(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Erinnerung gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Erinnerung konnte nicht gespeichert werden.", false, true, 3000);
    });
}

export function dbEditServiceReminder(currentBuildingId, serviceId, data, NotificationObject, callback) {
    console.log("db call: dbEditServiceReminder");
    var docId = data.id;

    db.collection("buildings").doc(currentBuildingId).collection("services").doc(serviceId).collection("reminders").doc(docId).update(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Erinnerung gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Erinnerung konnte nicht gespeichert werden.", false, true, 3000);
    });
}

export function dbGetServiceReminders(currentBuildingId, serviceId, callback) {
    console.log("db call: dbGetServiceReminders");
    var result = [];
    db.collection("buildings").doc(currentBuildingId).collection("services").doc(serviceId).collection("reminders").get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var obj = doc.data();
            obj.id = doc.id;
            result.push(obj);
        });

        callback(result);
    });
}

export function dbDeleteServiceReminder(currentBuildingId, serviceId, id, NotificationObject, callback) {
    console.log("db call: dbDeleteServiceReminder");
    NotificationObject.show("Bitte warten", "Erinnerung wird gelöscht...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).collection("services").doc(serviceId).collection("reminders").doc(id).delete().then(function() {
        NotificationObject.show("Erfolg", "Erinnerung wurde gelöscht.", false, false, 3000);
        callback();
    }).catch(function(error) {
        NotificationObject.show("Fehler", "Erinnerung konnte nicht gelöscht werden.", false, true, 3000);
    });
}




export function dbCreateTicket(currentBuildingId, data, NotificationObject, callback) {
    console.log("db call: dbCreateTicket");
    NotificationObject.show("Bitte warten", "Ticket wird gespeichert...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).collection("tickets").add(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Ticket gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Ticket konnte nicht gespeichert werden.", false, true, 3000);
    });
}


export function dbUpdateTicket(currentBuildingId, id, data, NotificationObject, callback) {
    console.log("db call: dbUpdateTicket");
    NotificationObject.show("Bitte warten", "Ticket wird gespeichert...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).collection("tickets").doc(id).set(data)
    .then(function() {
        NotificationObject.show("Erfolg", "Ticket gespeichert.", false, false, 3000);
        callback();
    })
    .catch(function(error) {
        NotificationObject.show("Fehler", "Ticket konnte nicht gespeichert werden.", false, true, 3000);
    });
}

export function dbGetTickets(currentBuildingId, callback) {
    console.log("db call: dbGetTickets");
    var result = [];
    db.collection("buildings").doc(currentBuildingId).collection("tickets").get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var obj = doc.data();
            obj.id = doc.id;
            result.push(obj);
        });

        callback(result);
    });
}

export function dbDeleteTicket(currentBuildingId, id, NotificationObject, callback) {
    console.log("db call: dbDeleteTicket");
    NotificationObject.show("Bitte warten", "Ticket wird gelöscht...", true, false, 0);

    db.collection("buildings").doc(currentBuildingId).collection("tickets").doc(id).delete().then(function() {
        NotificationObject.show("Erfolg", "Ticket wurde gelöscht.", false, false, 3000);
        callback();
    }).catch(function(error) {
        NotificationObject.show("Fehler", "Ticket konnte nicht gelöscht werden.", false, true, 3000);
    });
}

export function dbGetBuildingsForUser(NotificationObject, callback) {
    console.log("db call: dbGetBuildingsForUser");
    NotificationObject.show("Bitte warten", "Gebäude werden geladen...", true, false, 0);

    if (!auth().currentUser) {
        auth().onAuthStateChanged(function(user) {
            if (user) {
                getBuildings(auth().currentUser.uid, NotificationObject, callback);
            }
          });
    } else {
        getBuildings(auth().currentUser.uid, NotificationObject, callback);
    }

}

function getBuildings(user, NotificationObject, callback) {
    console.log("db call: getBuildings");
    var result = [];

    console.log("calling user id " + user)
    db.collection("auth").doc(user).collection("buildings").get()
    .then((querySnapshot) => {
        var count = 0;
        if (querySnapshot.size === 0) {
            callback(result);
            return; 
        }
        querySnapshot.forEach((doc) => {
            count++;
            db.collection("buildings").doc(doc.id).get().then(function(building) {
                if (building.exists) {
                    var obj = building.data();
                    obj.id = doc.id;
                    result.push(obj);
                } 

                if (count >= querySnapshot.size) {
                    NotificationObject.show("Erfolg", "Gebäude erfolgreich geladen.", false, false, 3000);
                    callback(result);
                }
            }).catch(function(error) {
                console.log("error2: " + JSON.stringify(error))
                NotificationObject.show("Fehler", "Gebäude konnten nicht geladen werden.", false, true, 3000);
            });
        });
    }).catch(function(error) {
        console.log("error: " + JSON.stringify(error))
        NotificationObject.show("Fehler", "Gebäude konnten nicht geladen werden.", false, true, 3000);
    });
}