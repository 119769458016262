import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faClipboardList, faNewspaper, faUsers, faTools, faFileContract,faArrowLeft, faToolbox, faBlender, faSkullCrossbones, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { getRoleForThisBuilding } from "../helpers/auth";


export default class Sidebar extends Component {

    constructor (props) {
        super(props);
        this.state = {
            role: undefined
        };
    }
        
    componentDidMount () {
        getRoleForThisBuilding(this.props.currentBuildingId, function(role) {
            this.setState({ 
                role: role
              });
        }.bind(this));
    }

  render() {

    const pages = [
        {"header": true, "title": "Allgemein"},
        {"link": "building", "title": "Übersicht", "icon": faBuilding, roles: ["tenant_user", "tenant_admin", "building_user", "building_admin"]},
        {"link": "news", "title": "News", "icon": faNewspaper, roles: ["tenant_user", "tenant_admin", "building_user", "building_admin"]},
        {"header": true, "title": "Verwaltung Mieter"},
        {"link": "tenants", "title": "Übersicht Mieter", "icon": faUsers, roles: ["building_user", "building_admin"]},
        {"link": "communication", "title": "Kommunikation", "icon": faMailBulk, roles: ["building_user", "building_admin"]},
        {"header": true, "title": "Verwaltung Gebäude"},
        {"link": "assets", "title": "Anlagen & Bauteile", "icon": faToolbox, roles: ["building_user", "building_admin"]},
       // {"link": "items", "title": "Geräte & Gegenstände", "icon": faBlender, roles: ["tenant_user", "tenant_admin", "building_user", "building_admin"]},
        {"link": "service", "title": "Verträge & Services", "icon": faFileContract, roles: ["tenant_user", "tenant_admin", "building_user", "building_admin"]},
        {"link": "maintenance", "title": "Wartung", "icon": faTools, roles: ["tenant_user", "tenant_admin", "building_user", "building_admin"]},
        {"header": true, "title": "Bauphase"},
        {"link": "defects", "title": "Mängel", "icon": faSkullCrossbones, roles: ["building_user", "building_admin"]},
       // {"header": true, "title": "Anfragen & Probleme"},
       // {"link": "tickets", "title": "Tickets", "icon": faClipboardList, roles: ["tenant_user", "tenant_admin", "building_user", "building_admin"]}   
    ];

    var self = this;
    var linkList = pages.map(function(page) {
        if (page.header) {
            return (
                <li className="flex-1 mt-8" key={page.title}>
                    <span className="text-gray-600">{page.title}</span>
                </li>);
        } else if (self.state.role && page.roles.includes(self.state.role)) {
            var linkClasses = "block py-1 md:py-3 pl-1 align-middle text-gray-800 no-underline hover:text-main-100 border-b-2 border-gray-800 md:border-gray-900 hover:border-main-100";
            var iClasses = "2fa pr-0 md:pr-3";
            var spanClasses = "pb-1 md:pb-0 text-gray-600 md:text-gray-400 block md:inline-block text-base";
            if (page.link === self.props.active) {
                linkClasses = "block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-main-100";
                iClasses = "fa pr-0 md:pr-3 text-white-100";
                spanClasses = "pb-1 md:pb-0 text-white block md:inline-block text-base";
            }

            var link = "/" + self.props.currentBuildingId + "/" + page.link;

            return (
                <li className="ml-2 flex-1" key={page.link}>
                    <Link to={link} className={linkClasses}>
                        <i className={iClasses}> <FontAwesomeIcon icon={page.icon}/></i><span className={spanClasses}>{page.title}</span>
                    </Link>
                </li>);
        } else {
            return (<li key={page.title}></li>);
        }
    });

    return (
         <div className="w-full md:w-1/6 bg-main-200 md:bg-main-200 px-2 text-center fixed bottom-0 md:pt-8 md:top-0 md:left-0 h-16 md:h-screen pr-10">
            <div className="md:relative mx-auto float-left ml-4">
                <div className="text-white mb-20"><span className="float-left text-xl logo"><Link to="/home"><FontAwesomeIcon icon={faArrowLeft} className="align-baseline text-sm mr-2"/>Skyline Office</Link></span></div>
               <ul className="list-reset flex flex-row md:flex-col text-center md:text-left">
                   {linkList}
               </ul>
            </div>
         </div>
    )
  }
}