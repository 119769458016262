import React, { Component } from 'react';

export default class ContactDialog extends Component {


constructor(props) {
    super(props);
    this.state = {
        open: false,
        callback: undefined,
        name: "",
        role: "",
        email: "",
        phone: "",
        mobile: "",
        isInfoContact: false,
        isEmergencyContact: false,
        isFinanceContact: false,
        showContactTypes: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
}

close() {
    this.setState({ 
        open: false,
        name: "",
        role: "",
        email: "",
        phone: "",
        mobile: "",
        isInfoContact: false,
        isEmergencyContact: false,
        isFinanceContact: false,
        showContactTypes: false
      });
}

save() {
  console.log("sav")
    this.state.callback(this.state.name, this.state.role, this.state.email, this.state.phone, this.state.mobile, this.state.isInfoContact, this.state.isEmergencyContact, this.state.isFinanceContact)
    this.close();
}

open(editingContact, showContactTypes, callback) {
    if (editingContact) {
      this.setState({ 
        open: true,
        callback: callback,
        name: editingContact.name,
        role: editingContact.role,
        email: editingContact.email,
        phone: editingContact.phone,
        mobile: editingContact.mobile,
        isInfoContact: editingContact.isInfoContact,
        isEmergencyContact: editingContact.isEmergencyContact,
        isFinanceContact: editingContact.isFinanceContact,
        showContactTypes: showContactTypes
      });
    } else {
      this.setState({ 
        open: true,
        callback: callback,
        name: "",
        role: "",
        email: "",
        phone: "",
        mobile: "",
        isInfoContact: false,
        isEmergencyContact: false,
        isFinanceContact: false,
        showContactTypes: showContactTypes
      });
    }
}

handleChange(event) {
  if (event.target.type === "checkbox") {
    console.log("current: " + event.target.checked)
    this.setState({
      [event.target.name]: event.target.checked
    });
  } else {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  }

componentDidMount() {
    this.props.onRef(this)
}
componentWillUnmount() {
    this.props.onRef(undefined)
}


  render() {

    var modalClasses = "z-10 modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center";
    if (this.state.open) {
        modalClasses = "z-10 modal fixed w-full h-full top-0 left-0 flex items-center justify-center";
    }

      console.log("this.state.showContactTypes : " + this.state.showContactTypes )
    return (
        <div className={modalClasses}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 "></div>
        
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

          <div className="modal-content py-4 text-left px-6">
            <div className="flex justify-between items-center pb-3">
              <p className="text-lg font-bold">Weiteren Kontakt hinzufügen</p>
            </div>
    
            <div className="mb-4">
                <label className="block mb-2">Name</label>
                <input onChange={this.handleChange} name="name" value={this.state.name} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Max Mustermann"></input>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Rolle</label>
                <input onChange={this.handleChange} name="role" value={this.state.role} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Geschäftsführer"></input>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Email</label>
                <input onChange={this.handleChange} name="email" value={this.state.email} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="max@mustermann.de"></input>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Telefon</label>
                <input onChange={this.handleChange} name="phone" value={this.state.phone} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="0931/12345678"></input>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Mobil</label>
                <input onChange={this.handleChange} name="mobile" value={this.state.mobile} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="0931/12345678"></input>
            </div>

            <div className={this.state.showContactTypes ? "flex mb-4" : "hidden"}>
              <label className="block float-left mr-8 mb-4">
                <input className="mr-2 leading-tight" type="checkbox" name="isInfoContact" checked={this.state.isInfoContact}  onChange={this.handleChange}></input>
                <span className="">
                  Info-Kontakt
                </span>
              </label> 

              <label className="block float-left mr-8 mb-4">
                <input className="mr-2 leading-tight" type="checkbox" name="isEmergencyContact" checked={this.state.isEmergencyContact}  onChange={this.handleChange}></input>
                <span className="">
                  Notfall-Kontakt
                </span>
              </label> 

              <label className="block float-left mr-8 mb-4">
                <input className="mr-2 leading-tight" type="checkbox" name="isFinanceContact" checked={this.state.isFinanceContact}  onChange={this.handleChange}></input>
                <span className="">
                  Buchhaltung-Kontakt
                </span>
              </label> 
            </div>
    
            <div className="flex justify-end pt-2">
              <button className="px-4 bg-transparent p-3 rounded text-main-100 hover:bg-main-200 hover:text-white mr-2" onClick={this.close}>Abbrechen</button>
              <button className="modal-close px-4 bg-main-100 p-3 rounded text-white hover:bg-main-200" onClick={this.save}>Speichern</button>
            </div>
            
          </div>
        </div>
      </div>
    )
  }

}