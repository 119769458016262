import React, { Component } from 'react';

const emptyTask = {
  open: false,
  title: "",
  description: "",
  reaction: "",
  location: "",
  priority: "LOW",
  responsible: "",
  dueDate: "",
  recurring: 0,
  intervall: 1
}

export default class TaskViewDialog extends Component {

    constructor(props) {
        super(props);
        this.state = emptyTask;

        this.close = this.close.bind(this);
        this.resolve = this.resolve.bind(this);
    }

    close() {
        this.setState(emptyTask);
    }

    resolve() {
      var confirmText = "Soll diese Aufgabe wirklich gelöscht werden? Bei wiederholenden Aufgaben wird automatisch die nächste Aufgabe erstellt.";
      if (this.state.callback && window.confirm(confirmText)) {
        this.state.callback(this.state.id);
      }
      this.close();
    }


    open(currentTask, callback) {
      if (currentTask) {
        this.setState({ 
          open: true,
          callback: callback,
          id: currentTask.id,
          title: currentTask.title || "",
          description: currentTask.description || "",
          reaction: currentTask.reaction || "",
          location: currentTask.location || "",
          priority: currentTask.priority || "LOW",
          responsible: currentTask.responsible ||"",
          dueDate: currentTask.dueDate || "",
          recurring: currentTask.recurring || 0,
          intervall: currentTask.intervall || 1,
          statusOpen: true
        });
      } 
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }


  render() {

    var modalClasses = "z-10 modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center";
    if (this.state.open) {
        modalClasses = "z-10 modal fixed w-full h-full top-0 left-0 flex items-center justify-center";
    }

    var prioText = "Niedrig";
    var prioColor =  "bg-gray-500 text-white text-sm rounded p-1";
    if (this.state.priority === "MEDIUM") {
      prioText = "Mittel";
      prioColor = "bg-orange-500 text-white text-sm rounded p-1";
    } else if (this.state.priority === "HIGH") {
      prioText = "Hoch";
      prioColor = "bg-red-500 text-white text-sm rounded p-1";
    }

    var recurringText = this.state.recurring ? "Wiederkehrend alle " +  this.state.intervall + " Wochen" : "Nicht wiederkehrend";
    return (
        <div className={modalClasses}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        
        <div className="modal-container bg-white w-11/12 md:w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">

          <div className="modal-content py-4 text-left px-6">
            <div className="flex justify-between items-center pb-3">
              <p className="text-lg font-bold">{this.state.title}</p>
            </div>
    
            <div className="flex flex-wrap">
                <div className="md:w-1/2 w-full">
                  <span className="block text-gray-500 text-sm">Ort im Gebäude</span>
                  <span className="block mb-2">{this.state.location}</span>
                </div>
             </div>

             <div className="flex flex-wrap">
                <div className="md:w-1/2 w-full">
                  <span className="block text-gray-500 text-sm">Was muss getan werden?</span>
                  <span className="block mb-2">{this.state.description}</span>
                </div>
                <div className="md:w-1/2 w-full">
                  <span className="block text-gray-500 text-sm">Was muss bei Problemen/Fehlern getan werden?</span>
                  <span className="block mb-2">{this.state.reaction}</span>
                </div>
             </div>

             <div className="flex flex-wrap">
                <div className="md:w-1/2 w-full">
                  <span className="block text-gray-500 text-sm">Verantwortlich</span>
                  <span className="block mb-2">{this.state.responsible}</span>
                </div>
                <div className="md:w-1/2 w-full">
                  <span className="block text-gray-500 text-sm">Priorität</span>
                  <span className={prioColor}>{prioText}</span>
                </div>
             </div>

            
             <div className="flex flex-wrap mt-2">
                <div className="w-full">
                  <span className="block mb-2 font-bold">Fällig: {this.state.dueDate}</span>
                </div>
             </div>

             <div className="flex flex-wrap mt-2">
                <div className="w-full">
                  <span className="block mb-2">{recurringText}</span>
                </div>
             </div>
    
            <div className="flex justify-end pt-2">
              <button className="px-4 bg-transparent p-3 rounded text-main-100 hover:bg-main-200 hover:text-white mr-2" onClick={this.close}>Schließen</button>
              <button className="modal-close px-4 bg-main-100 p-3 rounded text-white hover:bg-main-200" onClick={this.resolve}>Erfolgreich abgeschlossen</button>
            </div>
            
          </div>
        </div>
      </div>
    )
  }

}