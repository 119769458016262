import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Notification from '../components/Notification';
import TicketDialog from '../components/TicketDialog';
import { Link } from 'react-router-dom';

  import { 
    dbAddBuilding,
    dbGetAllBuildings,
    dbUpdateRole,
    dbGetAllUsers,
    dbAddUserToBuilding,
    dbRemoveUserFromBuilding} from '../helpers/db_users';

export default class Admin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "user",
      buildingName: "",
      buildings: [],
      users: [],
      selectedUser: ""
    };

    this.tabClicked = this.tabClicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addBuilding = this.addBuilding.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.addUserToBuilding = this.addUserToBuilding.bind(this);
  }

  componentDidMount() {
    dbGetAllBuildings(function(buildings) {
      this.setState({ 
        buildings: buildings.data
      });
    }.bind(this));

    dbGetAllUsers(function(users) {
      this.setState({ 
        users: users.data
      });
    }.bind(this));
  
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  addBuilding() {
    if (!this.state.buildingName || this.state.backupPath.buildingName < 5) {
      alert("Bitte einen namen angeben!");
      return;
    }
    if (window.confirm("Soll ein neues Gebäude [" + this.state.buildingName + "] erstellt werden?")) {
      var NotificationObject = this._notification;
      //NotificationObject.show("Backup gestartet", "Backup läuft im Hintergrund...", false, false, 5000);
      dbAddBuilding(this.state.buildingName, NotificationObject, function() {    
        dbGetAllBuildings(function(buildings) {
          this.setState({ 
            buildings: buildings.data
          });
        }.bind(this));
      }.bind(this));

    } 
  }

  removeUser(userId, buildingId) {
    var NotificationObject = this._notification;
    if (window.confirm("Soll der User wirklich von diesem Gebäude entfernt werden?")) {
      NotificationObject.show("Bitte warten", "User wird entfernt.", false, false, 3000);
      dbRemoveUserFromBuilding(buildingId, userId, function() {
        NotificationObject.show("Erfolg", "User wurde entfernt.", false, false, 3000);
        dbGetAllBuildings(function(buildings) {
          this.setState({ 
            buildings: buildings.data
          });
        }.bind(this));
      }.bind(this));
    }
  }

  addUserToBuilding(buildingId) {
    var NotificationObject = this._notification;

    NotificationObject.show("User wird hinzugefügt", "Läuft im Hintergrund...", false, false, 5000);
    dbAddUserToBuilding(buildingId, this.state.selectedUser, function(result) {
      console.log("dbAddUserToBuilding finished: " + result)
      dbGetAllBuildings(function(buildings) {
        this.setState({ 
          buildings: buildings.data
        });
        NotificationObject.show("Erfolg", "User wurde hinzugefügt...", false, false, 5000);
      }.bind(this));
    }.bind(this));
  }

  changeRole(userId, buildingId, value, roles) {
    var newRoles = roles;
    newRoles[userId] = value;
    var NotificationObject = this._notification;
    dbUpdateRole(buildingId, userId, value, NotificationObject, function(result) {
      console.log("change role finished " + result)
      dbGetAllBuildings(function(buildings) {
        this.setState({ 
          buildings: buildings.data
        });
      }.bind(this));
    }.bind(this));
  }

  tabClicked(tab) {
    this.setState({
      activeTab: tab
    });
  }


  render() {

    var activeTabClasses = "bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-main-200 font-semibold";
    var inactiveTabClasses = "bg-white inline-block py-2 px-4 text-main-100 hover:text-main-200 font-semibold";


    var allUsersList = this.state.users.map(function(user) {
      return (
        <option value={user.uid} key={user.uid}>{user.email}</option>
      );
    });


    var buildingList = this.state.buildings.map(function(building) {    
      console.log("building:")
      console.log(building)
      var userList = building.users.map(function(user) {

        var role = building.roles[user.uid];
        var key = building.id + "-" + user.uid;
        return (
          <tr className="border-b hover:bg-orange-100 bg-gray-100" key={key}>
            <td className="p-3 px-5">{user.email}</td>
            <td className="p-3 px-5">
              <select name="cars" value={role} onChange={(e) => this.changeRole(user.uid, building.id, e.target.value,  building.roles)}>
                <option value="-">-</option>
                <option value="building_admin">Gebäude Admin</option>
                <option value="building_user">Gebäude User</option>
                <option value="tenant_admin">Mieter Admin</option>
                <option value="tenant_user">Mieter User</option>
              </select></td>
            <td className="p-3 px-5">
              <button className="bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={(e) => this.removeUser(user.uid, building.id)}>
                Entfernen
              </button>
            </td>
        </tr>)
      }.bind(this));


      return (      
        <div className="mt-4 mb-8" key={building.id}>
        <h2 className="mt-8 mb-4 w-full">{building.admintitle || building.title}</h2>
        <div className="flex justify-center w-full">
          <table className="w-full text-md bg-white shadow-md rounded mb-4">
              <tbody>
                  <tr className="border-b">
                      <th className="text-left p-3 px-5">Email</th>
                      <th className="text-left p-3 px-5">Rolle</th>
                      <th className="text-left p-3 px-5"></th>
                  </tr>
                 
                 {userList}
                  
                </tbody>
            </table>
          </div>

          <div className="flex w-full">
            <select name="selectedUser" value={this.state.selectedUser} onChange={this.handleChange}>
              <option value="-">-</option>

              {allUsersList}
            </select>
            <button className="ml-4 bg-main-100 hover:bg-main-200 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={(e) => this.addUserToBuilding(building.id)}>
                    User zu diesem Gebäude hinzufügen
            </button>  
          </div>
        </div>
      )}.bind(this));

    return (
      <div className="flex md:flex-row-reverse flex-wrap">
        <Notification onRef={ref => (this._notification = ref)}></Notification>
        <TicketDialog onRef={ref => (this._ticketDialog = ref)}></TicketDialog>
		
         <div className="w-full h-screen w-screen bg-white">
           <Navbar title="Admin"></Navbar>
           <div className="p-2 mt-4">

            <ul className="flex border-b">
              <li className="-mb-px mr-1">
                <Link className={this.state.activeTab === "user" ? activeTabClasses : inactiveTabClasses} to="#" onClick={() => this.tabClicked('user')}>Gebäude & User</Link>
              </li>
            </ul>

            <div className={this.state.activeTab === "user" ? "m-8" : "hidden"}>
            <div className="flex">
                <div className="md:w-1/3">
                  <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Gebäudename:
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="buildingName" onChange={this.handleChange} value={this.state.buildingName} type="text" placeholder="Gebäude_1"></input>
                      </div>
                      <div className="flex items-center justify-between">
                        <button className="bg-main-100 hover:bg-main-200 text-white  py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={this.addBuilding}>
                          Neues Gebäude hinzufügen
                        </button>
                      </div>
                    </div>
                </div>  
                <div className="md:w-2/3 pl-8"></div> 
              </div>
            

              {buildingList}
            </div>

           </div>
         </div>
		 
      </div>
    )
  }
}