import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import EntryList from '../components/EntryList';
import Notification from '../components/Notification';
import DocumentList from '../components/DocumentList';
import ReminderList from '../components/ReminderList';
import AdditionalContacts from '../components/AdditionalContacts';
import History from '../components/History';


import { 
  dbAddEntry,
  dbGetEntries,
  dbUpdateEntry,
  dbDeleteEntry } from '../helpers/db_entry';

import { 
  dbAddServiceReminder,
  dbEditServiceReminder,
  dbGetServiceReminders,
  dbDeleteServiceReminder} from '../helpers/db';


const emptyService =  {
  title: "",
  infoCost: "",
  infoDurationUntil: "",
  infoDurationFrom: "",
  infoGuaranteeUntil: "",
  infoCompany: "",
  notes: "",
  contacts: [],
  events: []
};

export default class Service extends Component {

  
  constructor(props) {
      super(props);
      const { id, serviceId } = this.props.match.params;

      this.state = {
          page: "services",
          activeService: emptyService,
          services: [],
          infoEditable: false,
          noteEditable: false,
          currentBuildingId: id,
          urlServiceId: serviceId
      };

      this.handleChange = this.handleChange.bind(this);
      this.onActiveServiceChanged = this.onActiveServiceChanged.bind(this);
      this.onServiceAdded = this.onServiceAdded.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleNoteEdit = this.handleNoteEdit.bind(this);
      this.handleInfoEdit = this.handleInfoEdit.bind(this);
      this.deleteService = this.deleteService.bind(this);
      this.addContact = this.addContact.bind(this);
      this.deleteContact = this.deleteContact.bind(this);
      this.editContact = this.editContact.bind(this);
      this.addEvent = this.addEvent.bind(this);
      this.deleteEvent = this.deleteEvent.bind(this);
      this.editEvent = this.editEvent.bind(this);
    }

  componentDidMount() {
    dbGetEntries(this.state.currentBuildingId, this.state.page, function(data) {
      if (this.state.urlServiceId) {
        var activeService = emptyService;

        for (var i=0; i<data.length; i++) {
          if (data[i].id === this.state.urlServiceId) {
            activeService = data[i];
            break;
          }
        }

        this.setState({ 
          services: data,
          activeService: activeService
        });
      } else {
        this.setState({ 
          services: data
        });
      }
    }.bind(this));
  }

  handleChange(event) {
    var newActiveService = this.state.activeService;
    newActiveService[event.target.name] = event.target.value;

    this.setState({
      activeService: newActiveService
    });

  }

  handleSave() {
    this.setState({ infoEditable: false, noteEditable: false });
    var updatedService = {
      title: this.state.activeService.title,
      infoCost: this.state.activeService.infoCost,
      infoDurationUntil: this.state.activeService.infoDurationUntil,
      infoDurationFrom: this.state.activeService.infoDurationFrom,
      infoGuaranteeUntil: this.state.activeService.infoGuaranteeUntil,
      infoCompany: this.state.activeService.infoCompany,
      notes: this.state.activeService.notes,
      contacts: this.state.activeService.contacts,
      events: this.state.activeService.events || []
    };

    dbUpdateEntry(this.state.currentBuildingId, this.state.page, this.state.activeService.id, updatedService, this._notification);
  }

  handleInfoEdit() {
    this.setState({ infoEditable: true });
  }
  
  handleNoteEdit() {
    this.setState({ noteEditable: true });
  }

  addEvent(newEvent) {
    if (!this.state.activeService.events) {
      this.state.activeService.events = [];
    }
    this.state.activeService.events.push(newEvent);
    this.handleSave();
  }

  editEvent(newEvents) {
    var editedActiveService = this.state.activeService;
    editedActiveService.events = newEvents;
    this.setState({ 
      events: editedActiveService
    });
    this.handleSave();
  }

  deleteEvent(index) {
    this.state.activeService.events.splice(index, 1);
    this.handleSave();
  }


  onActiveServiceChanged(service) {
    this.props.history.push({
      pathname: "/" + this.state.currentBuildingId + "/service/" + service.id
    })
    this.setState({ 
      activeService: service,
      noteEditable: false,
      infoEditable: false
    });
  }

  deleteService() {
    if (window.confirm("Soll der Eintrag wirklich gelöscht werden?")) {
      var self = this;
      var id = this.state.activeService.id;
      dbDeleteEntry(this.state.currentBuildingId, this.state.page, id, this._notification, function() {
        var newServices = self.state.services.filter(s => (s.id !== id));
        self.setState({ services: newServices, activeService: emptyService });
      });
    }
  }

  onServiceAdded(service) {
    var NotificationObject = this._notification;
    var self = this;
    var newService = emptyService;
    newService.title = service;

    dbAddEntry(this.state.currentBuildingId, this.state.page, newService, NotificationObject, function() {
      dbGetEntries(self.state.currentBuildingId, self.state.page, function(data) {
        self.setState({ 
          services: data
        });
      });
    });
  }

  addContact(newContact) {
    if (!this.state.activeService.contacts) {
      this.state.activeService.contacts = [];
    }
    this.state.activeService.contacts.push(newContact);
    this.handleSave();
  }

  deleteContact(index, name) {
    this.state.activeService.contacts.splice(index, 1);
    this.handleSave();
  }

  editContact(newContacts) {
    var editedActiveService = this.state.activeService;
    editedActiveService.contacts = newContacts;
    this.setState({ 
      activeService: editedActiveService
    });
    this.handleSave();
  }


  render() {
    var detailVisibility = (this.state.activeService.title  && this.state.activeService.title.length > 0) ? "" : " hidden";
    var serviceId = (this.state.activeService.id  && this.state.activeService.id.length > 0) ? this.state.activeService.id : undefined;

    var noteInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.noteEditable) {
      noteInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    var infoInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.infoEditable) {
      infoInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    return (
      <div className="flex md:flex-row-reverse flex-wrap">

        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Services & Leasing" description="Alle Service- und Leasingverträge im Gebäude"></Navbar>

           <div className="flex">
            <div className="w-3/12 h-screen">
              <EntryList entries={this.state.services} selected={this.state.activeService} title="Eintrag hinzufügen" onActiveChanged={(a) => this.onActiveServiceChanged(a)} onEntryAdded={(a) => this.onServiceAdded(a)}></EntryList>
            </div>
            <div className="w-9/12">
            <div className={detailVisibility}>

            <div className="flex">
             <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Informationen
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.infoEditable} onClick={this.handleInfoEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.infoEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Firma:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeService.infoCompany} onChange={this.handleChange} name="infoCompany" className={infoInputClasses} type="text"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Kosten:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeService.infoCost} onChange={this.handleChange} name="infoCost" className={infoInputClasses} type="text"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Garantie bis:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeService.infoGuaranteeUntil} onChange={this.handleChange} name="infoGuaranteeUntil" className={infoInputClasses} type="date"></input>
                  </div>
                </div>


                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Laufzeit von:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeService.infoDurationFrom} onChange={this.handleChange} name="infoDurationFrom" className={infoInputClasses} type="date"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Laufzeit bis:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeService.infoDurationUntil} onChange={this.handleChange} name="infoDurationUntil" className={infoInputClasses} type="date"></input>
                  </div>
                </div>

              </div>
            
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Notizen 
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.noteEditable} onClick={this.handleNoteEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.noteEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                <textarea rows="15" readOnly={this.state.noteEditable ? false : "readonly"} value={this.state.activeService.notes} onChange={this.handleChange} name="notes" className={noteInputClasses} ></textarea>

                </div>

                </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <AdditionalContacts contacts={this.state.activeService.contacts} addFunction={this.addContact} deleteFunction={this.deleteContact} editFunction={this.editContact}></AdditionalContacts>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <History currentBuildingId={this.state.currentBuildingId} events={this.state.activeService.events} addFunction={this.addEvent} deleteFunction={this.deleteEvent} editFunction={this.editEvent}></History>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList currentBuildingId={this.state.currentBuildingId} folderId={this.state.activeService.gDriveFolderId}></DocumentList>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <ReminderList currentBuildingId={this.state.currentBuildingId} listId={serviceId} getFunction={dbGetServiceReminders} addFunction={dbAddServiceReminder} deleteFunction={dbDeleteServiceReminder} editFunction={dbEditServiceReminder}></ReminderList>
              </div>
            </div>

            <div className="flex mb-10 float-right">
              <div className="">
                <button onClick={this.deleteService} className="mr-4 text-sm mt-4 px-4 ml-4 py-2 leading-none border rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700">Eintrag löschen</button>
              </div>
            </div>

            </div>


            </div>
          </div>

         </div>
		
        <Sidebar active="service" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}