import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Notification from '../components/Notification';
import { signout } from "../helpers/auth";

import {
  dbGetBuildingsForUser
} from '../helpers/db';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buildings: []
    };

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    var self = this;
    dbGetBuildingsForUser(this._notification, function(buildings) {
      self.setState({ 
        buildings: buildings
      });
    });
  }

  logout() {
    if (window.confirm("Willst du dich wirklich ausloggen?")) {
      signout();
    }
  }


  render() {

    var buidingsList = this.state.buildings.map(function(building) {

      var link = "./" +  building.id + "/building";
      var subtitle = building.street + ", " + building.city

      return (                
        <Link className=" m-2" to={link} key={building.id}>
        <div className="shadow rounded bg-white p-4 m-4 cursor-pointer">
            <div>{building.title}</div>
            <div className="text-gray-500">{subtitle}</div>
        </div>
      </Link>
        )});


    return (
      <div className="flex h-screen bg-main-200">
        <Notification onRef={ref => (this._notification = ref)}></Notification>

        <div className="max-w-xl w-full lg:max-w-full flex flex-wrap m-auto justify-center">
          <div className="text-white flex">Hallo, hier findest du alle Gebäude für die du freigeschalet bist.</div>

          <div className="w-full justify-center flex">
            {buidingsList}
          </div>
          <Link className="text-main-100" to="#" onClick={this.logout}>Logout</Link>
      </div>
      </div>
    )
  }
}