import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faInfoCircle, faFirstAid, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import ContactDialog from '../components/ContactDialog';

export default class AdditionalContacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contacts: []
        };

        this.openAddContact = this.openAddContact.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.editContact = this.editContact.bind(this);
    }

    componentWillReceiveProps(props) {
      var contacts = props.contacts;
      if (!contacts) {
        contacts = [];
      }

      this.setState({
        contacts:  contacts,
        showContactTypes: props.showContactTypes
      });
    }

    openAddContact() {
      var self = this;
      self._addContactDialog.open(undefined, this.state.showContactTypes, function(name, role, email, phone, mobile, isInfoContact, isEmergencyContact, isFinanceContact) {
        var newContact = {
          name: name,
          role: role,
          email: email,
          phone: phone,
          mobile: mobile,
          isInfoContact: isInfoContact,
          isEmergencyContact: isEmergencyContact,
          isFinanceContact: isFinanceContact
        };
  
        self.props.addFunction(newContact);
      })
    }

    editContact(name) {
      var index = -1;
      for (var i=0; i<this.state.contacts.length; i++) {
        if (this.state.contacts[i].name === name) {
          index = i;
          break;
        }
      }
      
      if (index >= 0) {
        var self = this;
        self._addContactDialog.open(self.state.contacts[index], this.state.showContactTypes, function(name, role, email, phone, mobile, isInfoContact, isEmergencyContact, isFinanceContact) {
            self.state.contacts[index].name = name;
            self.state.contacts[index].role = role;
            self.state.contacts[index].email = email;
            self.state.contacts[index].phone = phone;
            self.state.contacts[index].mobile = mobile;
            self.state.contacts[index].isInfoContact = isInfoContact;
            self.state.contacts[index].isEmergencyContact = isEmergencyContact;
            self.state.contacts[index].isFinanceContact = isFinanceContact;

            self.setState({ 
              contacts: self.state.contacts
            });

            self.props.editFunction(self.state.contacts);
        })
      }
    }


    deleteContact(name) {
      if (window.confirm("Soll dieser Kontakt wirklich gelöscht werden? (" + name + ")")) {
        var index = -1;
        for (var i=0; i<this.state.contacts.length; i++) {
          if (this.state.contacts[i].name === name) {
            index = i;
            break;
          }
        }
        
        if (index >= 0) {
          this.props.deleteFunction(index, name);
        }
      }
    }

  render() {
    this.state.contacts.sort(function(a, b){return a.name >= b.name});
    var contactList = this.state.contacts.map(function(contact, index) {
      return (                
        <div className="flex flex-wrap -mx-3 mb-2" key={index}>
          <div className="w-full md:w-2/12 mb-6 md:mb-0">
            <input readOnly="readonly" value={contact.name} className="appearance-none bg-gray-100 w-full py-1 px-4 text-gray-700 leading-tight text-sm" type="text"></input>
          </div>

          <div className="w-full md:w-2/12 mb-6 md:mb-0">
            <input readOnly="readonly" value={contact.role} className="bg-gray-100 appearance-none rounded w-full py-1 px-4 text-gray-700 leading-tight text-sm"  type="text"></input>
          </div>

          <div className="w-full md:w-2/12 mb-6 md:mb-0">
            <a className="mailto" href={"mailto:" + contact.email}>
            <input readOnly="readonly" value={contact.email} className="bg-gray-100 appearance-none w-full py-1 px-4 text-gray-700 leading-tight text-sm" type="text"></input>
            </a>
          </div>

          <div className="w-full md:w-2/12 mb-6 md:mb-0">
            <a href={"tel:" + contact.phone}>
              <input readOnly="readonly" value={contact.phone} className="bg-gray-100 appearance-none w-full py-1 px-4 text-gray-700 leading-tight text-sm" type="text"></input>
            </a>
          </div>

          <div className="w-full md:w-2/12 mb-6 md:mb-0">
            <a href={"tel:" + contact.mobile}>
              <input readOnly="readonly" value={contact.mobile} className="bg-gray-100 appearance-none w-full py-1 px-4 text-gray-700 leading-tight text-sm" type="text"></input>
            </a>
          </div>

          <div className={this.state.showContactTypes ? "w-full md:w-1/12 mb-6 md:mb-0" : "hidden"}>
            <FontAwesomeIcon icon={faInfoCircle} style={{color:"#6DB65B"}} className={contact.isInfoContact ? "mr-2" : "hidden"}/>
            <FontAwesomeIcon icon={faFirstAid} style={{color:"#FF2F2F"}} className={contact.isEmergencyContact ? "mr-2" : "hidden"}/>
            <FontAwesomeIcon icon={faFileInvoiceDollar} style={{color:"#3F2FFF"}} className={contact.isFinanceContact ? "" : "hidden"}/>
          </div>

          <div className="w-full md:w-1/12 mb-6 md:mb-0 items-end">
              <button onClick={() => this.editContact(contact.name)} className="align-bottom mt-1 ml-4"><FontAwesomeIcon icon={faEdit} /></button>
              <button onClick={() => this.deleteContact(contact.name)} className="align-bottom mt-1 ml-4"><FontAwesomeIcon icon={faTimes} /></button>
            </div>
        </div>
        )}.bind(this));

    return (
        <div>
            <ContactDialog onRef={ref => (this._addContactDialog = ref)}></ContactDialog>

            <h3 className="mb-8 font-bold">Kontakte <button className="text-xs text-main-100 ml-3" onClick={this.openAddContact}>+ Hinzufügen</button></h3>

            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                <label className="block tracking-wide text-black mb-2">Name</label>
              </div>

              <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                <label className="block tracking-wide text-black mb-2">Rolle</label>
              </div>

              <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                <label className="block tracking-wide text-black mb-2">Email</label>
              </div>

              <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                <label className="block tracking-wide text-black mb-2">Telefon</label>
              </div>

              <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                <label className="block tracking-wide text-black mb-2">Mobil</label>
              </div>
              <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0 items-end"></div>
              <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0 items-end"></div>
            </div>
            {contactList}
        </div>
    )
  }
}