import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import ReminderDialog from './ReminderDialog';
import Notification from '../components/Notification';


export default class ReminderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reminders: [],
            currentBuildingId: this.props.currentBuildingId
        };

        this.openReminderDialog = this.openReminderDialog.bind(this);
        this.editReminder = this.editReminder.bind(this);
        this.deleteReminder = this.deleteReminder.bind(this);
    }

    componentDidMount() {
        if (this.props.listId !== undefined) {
          this.props.getFunction(this.state.currentBuildingId, this.props.listId, function(data) {
            this.setState({
                reminders: data
            });
          }.bind(this));
        }
      }

      componentDidUpdate(prevProps) {
        if (this.props.listId !== undefined) {
          if (prevProps === undefined || prevProps.listId !== this.props.listId) {
            this.props.getFunction(this.state.currentBuildingId, this.props.listId, function(data) {
              this.setState({
                  reminders: data
              });
            }.bind(this));
          }
        }
      }

      openReminderDialog() {
        var NotificationObject = this._notification;
        var self = this;
    
        self._reminderDialog.open(undefined, function(description, date, email) {
            self.props.addFunction(self.state.currentBuildingId, self.props.listId, {
                "description": description,
                "date": date,
                "email": email
              }, NotificationObject, function() {
                self.props.getFunction(self.state.currentBuildingId, self.props.listId, function(data) {
                  self.setState({
                    reminders: data
                  });
                });
              });
        });
    }

    editReminder(id) {
      var NotificationObject = this._notification;
      var self = this;

      var index = -1;
      for (var i=0; i<this.state.reminders.length; i++) {
        if (this.state.reminders[i].id === id) {
          index = i;
          break;
        }
      }
      
      if (index >= 0) {
        self._reminderDialog.open(self.state.reminders[index], function(description, date, email) {
            self.state.reminders[index].description = description;
            self.state.reminders[index].date = date;
            self.state.reminders[index].email = email;


            self.props.editFunction(self.state.currentBuildingId, self.props.listId,  self.state.reminders[index], NotificationObject, function() {
              self.setState({ 
                reminders: self.state.reminders
              });
            });
        })
      }
    }
   
    deleteReminder(id) {
      if (window.confirm("Soll dieses Erinnerung wirklich gelöscht werden?")) {
        var NotificationObject = this._notification;
        var self = this;
        self.props.deleteFunction(self.state.currentBuildingId, self.props.listId, id, NotificationObject, function() {
            var newReminders = self.state.reminders.filter(r => (r.id !== id));
            self.setState({ 
                reminders: newReminders
            });
        });
      }
    }

  render() {

    this.state.reminders.sort(function(a, b){return a.date >= b.date});
    var reminderList = this.state.reminders.map(function(reminder) {    
      console.log("rendering:")
      console.log(JSON.stringify(reminder))
        return (                
            <div className="flex flex-wrap -mx-3 mb-2" key={reminder.id}>
                <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                <input disabled={true} value={reminder.description} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text"></input>
                </div>
    
                <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                <input disabled={true} value={reminder.date} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text"></input>
                </div>
    
                <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                <input disabled={true} value={reminder.email} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text"></input>
                </div>
    
                <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0 items-end">
                    <button onClick={() => this.editReminder(reminder.id)} className="align-bottom mt-1 ml-4"><FontAwesomeIcon icon={faEdit} /></button>
                    <button onClick={() => this.deleteReminder(reminder.id)} className="align-bottom mt-2 ml-4"><FontAwesomeIcon icon={faTimes} /></button>
                </div>
            </div>
          )}.bind(this));

    return (
        <div>
            <Notification onRef={ref => (this._notification = ref)}></Notification>
            <ReminderDialog onRef={ref => (this._reminderDialog = ref)}></ReminderDialog>
            <h3 className="mb-8 font-bold">Erinnerungen <button className="text-xs text-main-100 ml-3" onClick={this.openReminderDialog}>+ Hinzufügen</button></h3>
            {reminderList}
        </div>
    )
  }
}