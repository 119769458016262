import React, { Component } from 'react';

const emptyTask = {
  open: false,
  editMode: true,
  successCallback: undefined,
  errorCallback: undefined,
  title: "",
  description: "",
  reaction: "",
  location: "",
  priority: "LOW",
  responsible: "",
  dueDate: "",
  recurring: 0,
  intervall: 1,
  statusOpen: true
}

export default class TaskDialog extends Component {

constructor(props) {
    super(props);
    this.state = emptyTask;

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
}

close() {
    this.setState(emptyTask);
}

save() {
  var finalTask = {
    title: this.state.title,
    description: this.state.description,
    reaction: this.state.reaction,
    location: this.state.location,
    priority: this.state.priority,
    responsible: this.state.responsible,
    dueDate: this.state.dueDate,
    recurring: this.state.recurring,
    intervall: this.state.intervall,
    statusOpen: true
  }

  if (this.state.successCallback) {
    this.state.successCallback(finalTask);
  }
  this.close();
}


open(editMode, currentTask, successCallback, errorCallback) {
  if (currentTask) {
    this.setState({ 
      open: true,
      editMode: editMode,
      successCallback: successCallback,
      errorCallback: errorCallback,
      title: currentTask.title || "",
      description: currentTask.description || "",
      reaction: currentTask.reaction || "",
      location: currentTask.location || "",
      priority: currentTask.priority || "LOW",
      responsible: currentTask.responsible ||"",
      dueDate: currentTask.dueDate || "",
      recurring: currentTask.recurring || 0,
      intervall: currentTask.intervall || 1,
      statusOpen: true
    });
  } else {
    this.setState({ 
      open: true,
      editMode: editMode,
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
}

handleChange(event) {
  var newValue = event.target.value;
  if (event.target.type === "checkbox") {
    newValue = event.target.checked
  }
  this.setState({
    [event.target.name]: newValue
  });
  }

componentDidMount() {
    this.props.onRef(this)
}
componentWillUnmount() {
    this.props.onRef(undefined)
}


  render() {

    var modalClasses = "z-10 modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center";
    if (this.state.open) {
        modalClasses = "z-10 modal fixed w-full h-full top-0 left-0 flex items-center justify-center";
    }

    var recurringText = this.state.recurring ? "Wiederkehrend" : "Nicht wiederkehrend";
    var intervallClassNames = this.state.recurring ? "md:w-1/2 w-full p-2" : "md:w-1/2 w-full p-2 hidden";

    return (
        <div className={modalClasses}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        
        <div className="modal-container bg-white w-11/12 md:w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">

          <div className="modal-content py-4 text-left px-6">
            <div className="flex justify-between items-center pb-3">
              <p className="text-lg font-bold">Aufgabe erstellen/bearbeiten</p>
            </div>
    
            <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                  <label className="block mb-2">Titel</label>
                  <input onChange={this.handleChange} name="title" value={this.state.title} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Filter überprüfen"></input>
                </div>
                <div className="md:w-1/2 w-full p-2">
                  <label className="block mb-2">Ort im Gebäude</label>
                  <input onChange={this.handleChange} name="location" value={this.state.location} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="HAR"></input>
                </div>
             </div>

             <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                <label className="block mb-2">Was muss getan werden?</label>
                <textarea onChange={this.handleChange} name="description" value={this.state.description} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="textarea" size="3" placeholder="Filter überprüfen und falls verschmutzt tauschen"></textarea>
                </div>
                <div className="md:w-1/2 w-full p-2">
                <label className="block mb-2">Was muss bei Problemen/Fehlern getan werden?</label>
                <textarea onChange={this.handleChange} name="reaction" value={this.state.reaction} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="textarea" size="3" placeholder="Termin bei Firma X unter der Nummer 1234567 ausmachen"></textarea>
                </div>
             </div>

            <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                  <label className="block mb-2">Verantwortlich</label>
                  <input onChange={this.handleChange} name="responsible" value={this.state.responsible} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="text" placeholder="Max Mustermann"></input>
                </div>
                <div className="md:w-1/2 w-full p-2">
                  <label className="block mb-2">Priorität</label>
                  <div className="relative">
                      <select onChange={this.handleChange} name="priority" value={this.state.priority} className="block appearance-none w-full bg-gray-200 border-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-main-200">
                          <option value="LOW">Niedrig</option>
                          <option value="MEDIUM">Mittel</option>
                          <option value="HIGH">Hoch</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                  </div>
                </div>
             </div>


             <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2">
                  <label className="block mb-2">Fällig am</label>
                  <input onChange={this.handleChange} name="dueDate" value={this.state.dueDate} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="date" placeholder="2020-01-01"></input>
                </div>
                <div className="md:w-1/2 w-full p-2">
                </div>
             </div>

             <div className="flex flex-wrap mb-2">
                <div className="md:w-1/2 w-full p-2 pb-6">
                  <label className="block mb-2">Wiederkehrend?</label>
                  <label htmlFor="toogleA" className="flex items-center cursor-pointer mt-4">
                    <div className="relative">
                      <input id="toogleA" type="checkbox" name="recurring" onChange={this.handleChange} className="hidden" value={this.state.recurring} checked={this.state.recurring}/>
                      <div className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                    </div>
                    <div className="ml-6 text-gray-700 font-medium">{recurringText}</div>
                  </label>
                </div>
                <div className={intervallClassNames}>
                  <label className="block mb-2">Intervall in Wochen</label>
                  <input onChange={this.handleChange} name="intervall" value={this.state.intervall} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200" type="number" placeholder="4"></input>  
                </div>
             </div>
    
            <div className="flex justify-end pt-2">
              <button className="px-4 bg-transparent p-3 rounded text-main-100 hover:bg-main-200 hover:text-white mr-2" onClick={this.close}>Abbrechen</button>
              <button className="modal-close px-4 bg-main-100 p-3 rounded text-white hover:bg-main-200" onClick={this.save}>Speichern</button>
            </div>
            
          </div>
        </div>
      </div>
    )
  }

}