import React, { Component } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import EntryList from '../components/EntryList';
import Notification from '../components/Notification';
import DocumentList from '../components/DocumentList';
import ReminderList from '../components/ReminderList';
import AdditionalContacts from '../components/AdditionalContacts';


import { 
  dbAddEntry,
  dbGetEntries,
  dbUpdateEntry,
  dbDeleteEntry } from '../helpers/db_entry';

import { 
  dbAddTenantReminder,
  dbEditTenantReminder,
  dbGetTenantReminders,
  dbDeleteTenantReminder} from '../helpers/db';


const emptyTenant =  {
  title: "",
  place: "",
  area: "",
  notes: "",
  renterFrom: "2020-01-01",
  renterTo: "2020-01-01",
  contacts: []
};

export default class Tenants extends Component {

  
  constructor(props) {
      super(props);
      const { id, tenantId } = this.props.match.params;

      this.state = {
          page: "tenants",
          activeTenant: emptyTenant,
          tenants: [],
          notesEditable: false,
          infoEditable: false,
          currentBuildingId: id,
          urlTenantId: tenantId
      };

      this.handleChange = this.handleChange.bind(this);
      this.onActiveTenantChanged = this.onActiveTenantChanged.bind(this);
      this.onTenantAdded = this.onTenantAdded.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleNotesEdit = this.handleNotesEdit.bind(this);
      this.handleInfoEdit = this.handleInfoEdit.bind(this);
      this.deleteTenant = this.deleteTenant.bind(this);
      this.addContact = this.addContact.bind(this);
      this.deleteContact = this.deleteContact.bind(this);
      this.editContact = this.editContact.bind(this);
  }

  componentDidMount() {
    dbGetEntries(this.state.currentBuildingId, this.state.page, function(data) {
      if (this.state.urlTenantId) {
        var activeTenant = emptyTenant;

        for (var i=0; i<data.length; i++) {
          if (data[i].id === this.state.urlTenantId) {
            activeTenant = data[i];
            break;
          }
        }

        this.setState({ 
          tenants: data,
          activeTenant: activeTenant
        });
      } else {
        this.setState({ 
          tenants: data
        });
      }
    }.bind(this));
  }

  handleChange(event) {
    var newActiveTenant = this.state.activeTenant;
    newActiveTenant[event.target.name] = event.target.value;

    this.setState({
        activeTenant: newActiveTenant
      });
  }

  handleSave() {
    this.setState({ notesEditable: false, infoEditable: false });
    
    var updatedTenant = {
      title: this.state.activeTenant.title || "",
      place: this.state.activeTenant.place || "",
      area: this.state.activeTenant.area || "",
      notes: this.state.activeTenant.notes || "",
      renterFrom: this.state.activeTenant.renterFrom || "",
      renterTo: this.state.activeTenant.renterTo || "",
      contacts: this.state.activeTenant.contacts || []
    };


    dbUpdateEntry(this.state.currentBuildingId, this.state.page, this.state.activeTenant.id, updatedTenant, this._notification);
  }

  handleNotesEdit() {
    this.setState({ notesEditable: true });
  }

  handleInfoEdit() {
    this.setState({ infoEditable: true });
  }

  onActiveTenantChanged(tenant) {
    this.props.history.push({
      pathname: "/" + this.state.currentBuildingId + "/tenants/" + tenant.id
    })

    if (!tenant.contacts) {
      tenant.contacts = [];
    }
    this.setState({ 
      activeTenant: tenant,
      noteEditable: false,
      infoEditable: false
    });
  }

  deleteTenant() {
    if (window.confirm("Soll der Mieter wirklich gelöscht werden?")) {
      var self = this;
      var id = this.state.activeTenant.id;
      dbDeleteEntry(this.state.currentBuildingId, this.state.page, id, this._notification, function() {
        var newTenants = self.state.tenants.filter(t => (t.id !== id));
        emptyTenant.title = "";
        self.setState({ tenants: newTenants, activeTenant: emptyTenant });
      });
    }
  }

  onTenantAdded(tenant) {
    var NotificationObject = this._notification;
    var self = this;
    var newTenant = emptyTenant;
    newTenant.title = tenant;

    dbAddEntry(this.state.currentBuildingId, this.state.page, newTenant, NotificationObject, function() {
      dbGetEntries(self.state.currentBuildingId, self.state.page, function(data) {
        self.setState({ 
          tenants: data
        });
      });
    });
  }

  addContact(newContact) {
    var newContacts = [];
    if (this.state.activeTenant.contacts) {
      newContacts = this.state.activeTenant.contacts;
    }
    newContacts.push(newContact);
    var newActiveTenant = this.state.activeTenant;
    newActiveTenant.contacts = newContacts;

    this.setState({ 
      activeTenant: newActiveTenant
    });
    
    //this.state.activeTenant.contacts.push(newContact);
    this.handleSave();
  }

  deleteContact(index, name) {
    this.state.activeTenant.contacts.splice(index, 1);
    this.handleSave();
  }

  editContact(newContacts) {
    var editedActiveTenant = this.state.activeTenant;
    editedActiveTenant.contacts = newContacts;
    this.setState({ 
      activeTenant: editedActiveTenant
    });
    this.handleSave();
  }


  render() {

    var detailVisibility = (this.state.activeTenant.title  && this.state.activeTenant.title.length > 0) ? "" : " hidden";
    var tenantId = (this.state.activeTenant.id  && this.state.activeTenant.id.length > 0) ? this.state.activeTenant.id : undefined;

    var noteInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight";
    if (this.state.notesEditable) {
      noteInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }
    var infoInputClasses = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-main-200";
    if (this.state.infoEditable) {
      infoInputClasses = "appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none bg-white border-main-200 focus:outline-none focus:bg-white focus:border-main-200";
    }

    return (
      <div className="flex md:flex-row-reverse flex-wrap">
        <Notification onRef={ref => (this._notification = ref)}></Notification>
		
         <div className="w-full md:w-5/6 bg-gray-100">
           <Navbar title="Mieter"></Navbar>

           <div className="flex">
            <div className="w-3/12 h-screen">
              <EntryList entries={this.state.tenants} selected={this.state.activeTenant} title="Mieter hinzufügen" onActiveChanged={(a) => this.onActiveTenantChanged(a)} onEntryAdded={(a) => this.onTenantAdded(a)}></EntryList>
            </div>
            <div className="w-9/12">
            <div className={detailVisibility}>

            <div className="flex">
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Informationen 
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.infoEditable} onClick={this.handleInfoEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.infoEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Bezeichnung:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeTenant.title || ""} onChange={this.handleChange} name="title" className={infoInputClasses} type="text"></input>
                  </div>
                </div>


                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Mieteinheit:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeTenant.place || ""} onChange={this.handleChange} name="place" className={infoInputClasses} type="text"></input>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-2">
                  <div className="md:w-1/3">
                    <label className="block mb-1 md:mb-0 pr-4">Mietfläche:</label>
                  </div>
                  <div className="md:w-2/3">
                    <input readOnly={this.state.infoEditable ? false : "readonly"} value={this.state.activeTenant.area || ""} onChange={this.handleChange} name="area" className={infoInputClasses} type="text"></input>
                  </div>
                </div>

            
              </div>
              <div className="w-1/2 border border-grey-100 rounded m-4 p-6">
                <h3 className="mb-8 font-bold">Notizen
                  <button className="text-xs text-main-100 ml-3" hidden={this.state.notesEditable} onClick={this.handleNotesEdit}>+ Bearbeiten</button>
                  <button className="text-xs text-main-100 ml-3" hidden={!this.state.notesEditable} onClick={this.handleSave}>+ Speichern</button>
                </h3>

                <div className="md:flex md:items-center mb-2">
                  <textarea rows="15" readOnly={this.state.notesEditable ? false : "readonly"} value={this.state.activeTenant.notes} onChange={this.handleChange} name="notes" className={noteInputClasses} ></textarea>
                </div>
              </div>
              </div>


            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <AdditionalContacts showContactTypes={true} contacts={this.state.activeTenant.contacts} addFunction={this.addContact} deleteFunction={this.deleteContact} editFunction={this.editContact}></AdditionalContacts>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <ReminderList currentBuildingId={this.state.currentBuildingId} listId={tenantId} getFunction={dbGetTenantReminders} addFunction={dbAddTenantReminder} deleteFunction={dbDeleteTenantReminder} editFunction={dbEditTenantReminder}></ReminderList>
              </div>
            </div>

            <div className="flex mb-1">
              <div className="w-full border border-grey-100 rounded m-4 p-6">
                <DocumentList currentBuildingId={this.state.currentBuildingId} folderId={this.state.activeTenant.gDriveFolderId}></DocumentList>
              </div>
            </div>

            <div className="flex mb-10 float-right">
              <div className="">
                  <button onClick={this.deleteTenant} className="mr-4 float-right text-sm mt-4 px-4 ml-4 py-2 leading-none border rounded text-red-700 border-red-700 hover:border-transparent hover:text-white hover:bg-red-700">Mieter löschen</button>
                </div>
            </div>

            </div>


            </div>
          </div>

         </div>
		
        <Sidebar active="tenants" currentBuildingId={this.state.currentBuildingId}></Sidebar>

      </div>
    )
  }
}